import { Grid, Stack, Typography } from "@mui/material";
import aboutImage from "public/images/topics/diabetes/about.jpg";

import { defaultGridSpacing } from "layout/configuration";

import Image from "common/components/Image";

const Diabetes = () => (
  <Grid container spacing={defaultGridSpacing}>
    <Grid item xs={12} md={7}>
      <Stack gap={2}>
        <Typography>
          Diabetes, also known as hyperglycemia, is a chronic disease in which levels of glucose in
          the blood are above normal. There are three types of diabetes: Type 1, Type 2, and
          gestational diabetes. It is estimated that only about 5 percent of all diagnosed cases of
          diabetes are type 1, where the pancreas is unable to produce insulin. Type 2 diabetes is
          where the pancreas cannot produce enough insulin, known as insulin resistance.
          Approximately 90 percent to 95 percent of diagnosed diabetes cases are diagnosed as type
          2.
        </Typography>
        <Typography>
          Gestational diabetes is a type of diabetes that occurs in pregnant women who did not have
          diabetes prior to pregnancy, and it is usually diagnosed between weeks 24 and 28.
          Gestational diabetes will resolve after delivery for most women. However, approximately
          half of all women who had gestational diabetes will develop type 2 diabetes later in their
          life.
        </Typography>
        <Typography>
          Pre-diabetes, sometimes referred to as impaired fasting glucose (IFG), is a condition
          where blood glucose levels are above normal but not high enough to be diagnosed with
          diabetes. People with pre-diabetes are at an increased risk for developing type 2
          diabetes.
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={12} md={5}>
      <Image
        borderRadius
        src={aboutImage}
        alt="Person pricking finder to get a sample for blood glucose level"
        credit={{
          name: "isens usa",
          url: "//unsplash.com/photos/a-person-holding-a-thermometer-next-to-a-thermometer-PeSjS7wo-lE"
        }}
      />
    </Grid>
  </Grid>
);

export default Diabetes;
