import { getMhcCategories, getMhcPages } from "graphqlApi/legacy/mhcClient";

import { MhcTopicCategoryEnum } from "graphqlApi/types";

import { MhcTopicCategories } from "layout/SideNav";
import { processTopics } from "./processTopics";

/**
 * @param locationId - the currently selected location id
 * @param allowedCategories - the allowed categories to load
 *
 * @returns a dictionary of the side nav items
 */
export const loadSideNavItems = async (
  locationId = "state",
  allowedCategories = [
    MhcTopicCategoryEnum.AllTopics,
    MhcTopicCategoryEnum.Portals,
    MhcTopicCategoryEnum.DataDownload,
    MhcTopicCategoryEnum.Reference
  ]
): Promise<MhcTopicCategories> => {
  const pages = await getMhcPages({
    categories: allowedCategories
  });
  const categories = await getMhcCategories({});

  return processTopics({ locationId, pages, categories, allowedCategories });
};
