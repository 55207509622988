import { MhcStratumGroupEnum } from "graphqlApi/types";

export const stratumGroupName = (group: MhcStratumGroupEnum): string => {
  switch (group) {
    case MhcStratumGroupEnum.Age:
      return "Age";
    case MhcStratumGroupEnum.Race:
      return "Race";
    case MhcStratumGroupEnum.Ethnicity:
      return "Ethnicity";
    case MhcStratumGroupEnum.Grade:
      return "Grade";
    case MhcStratumGroupEnum.Sex:
      return "Sex";
    case MhcStratumGroupEnum.RaceEthnicity:
      return "Race/Ethnicity";
    case MhcStratumGroupEnum.DrugType:
      return "Drug Type";
    case MhcStratumGroupEnum.DrugTypeEthnicity:
      return "Drug Type by Ethnicity";
    case MhcStratumGroupEnum.DrugTypeRace:
      return "Drug Type by Race";
    case MhcStratumGroupEnum.DrugTypeSex:
      return "Drug Type by Sex";
    case MhcStratumGroupEnum.Income:
      return "Income";
    case MhcStratumGroupEnum.AgeSex:
      return "Age/Sex";
    case MhcStratumGroupEnum.Education:
      return "Education";
    default:
      return group.replace(/_/g, " ").replace(/^\w|\s\w/g, (l) => l.toUpperCase());
  }
};
