import compact from "lodash/compact";
import isObject from "lodash/isObject";
import without from "lodash/without";

import {
  ConfigKeys,
  configKeys,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionTypeConfig
} from "../elementHelpers/dashboard/types";
import {
  MhcDateSeriesFragment,
  MhcGeographyEnum,
  MhcLocationFragment,
  MhcLocationStatFragment,
  MhcTimeSeriesFragment,
  Scalars
} from "graphqlApi/types";

import { CreateLoadedStatDictionary, createLoadedStatDictionary } from "./util";
import { getUtcDateFromString } from "common/util/utcDateFromString";

export interface LoadedDateSeries {
  dates?: MhcDateSeriesFragment["timeSeries"]["dates"];
  timestamps?: MhcTimeSeriesFragment["timeSeries"]["timestamps"];
  values: MhcDateSeriesFragment["timeSeries"]["values"];
  granularity?: MhcDateSeriesFragment["granularity"];
  confidenceIntervals?: MhcDateSeriesFragment["timeSeries"]["confidenceIntervals"];
}
export type LoadedStat = MhcLocationStatFragment & {
  __typeName?: string;
  timeSeries?: LoadedDateSeries;
  stratifications?: LoadedStat[];
  location?: MhcLocationFragment;
  lastUpdatedOn?: Scalars["ISO8601Date"] | null;
  lastValue?: number | null;
};

export type LoadedStatDictionary = { [id: string]: LoadedStat };
export type LoadedLocationStatDictionary = Record<string, LoadedStat>;
export type LoadedLocationStatDictionaryByLocation = Record<string, LoadedLocationStatDictionary>;

/**
 * Determines if section supports geography level
 *
 * @param params
 * @param params.locationGeography
 * @param params.subSectionConfig
 *
 * @returns true if the subSectionConfig supports the locationGeography
 */
const subSectionSupportsGeography = ({
  locationGeography,
  subSectionConfig
}: {
  locationGeography: MhcGeographyEnum;
  subSectionConfig: TopicDashboardSubsectionTypeConfig;
}) => {
  if (
    subSectionConfig.supportedGeographies &&
    !subSectionConfig.supportedGeographies?.includes(locationGeography)
  ) {
    return false;
  }
  return true;
};

/**
 * Get all stat identifier ids from a subsection configs
 *
 * @param subSectionConfig
 * @param locationGeography
 *
 * @returns an array of stat identifier ids as strings
 */
const getStatIdsFromConfigs = (
  subSectionConfig: TopicDashboardSubsectionTypeConfig | TopicDashboardSubsectionTypeConfig[],
  locationGeography?: MhcGeographyEnum
) => {
  const isArray = Array.isArray(subSectionConfig);

  if (!isArray && !isObject(subSectionConfig)) {
    return [];
  }

  const configs = isArray ? subSectionConfig : [subSectionConfig];

  return configs.flatMap((config: TopicDashboardSubsectionTypeConfig) => {
    if (
      locationGeography &&
      !subSectionSupportsGeography({ locationGeography, subSectionConfig: config })
    ) {
      return [];
    }
    return config.statIdConfigs ?? [];
  });
};

/**
 *
 *
 * @param locationId
 * @param dictionary
 *
 * @returns a dictionary of stat identifiers to loaded stats for the given location
 *
 * @see LoadedLocationStatDictionaryByLocation
 * @see LoadedLocationStatDictionary
 */
export const getLoadedStatDictionaryForLocation = (
  locationId: string,
  dictionary?: LoadedLocationStatDictionaryByLocation
): LoadedLocationStatDictionary => {
  if (dictionary === undefined) return {};
  const statDictionaryForLocation: LoadedLocationStatDictionary = {};
  Object.entries(dictionary).forEach(
    ([identifier, dict]: [string, LoadedLocationStatDictionary]) => {
      if (locationId in dict) {
        statDictionaryForLocation[identifier as string] = dict[locationId] as LoadedStat;
      }
    }
  );
  return statDictionaryForLocation;
};

/**
 * Creates a loaded stat dictionary for the first key (as locationId) of all stats
 * helpful if you need to get attributions of all the loaded stats that are agnostic
 * of location
 *
 * @param dictionary
 *
 * @returns LoadedLocationStatDictionary
 */
export const getStatDictionaryForAnyLocation = (
  dictionary: LoadedLocationStatDictionaryByLocation
): LoadedLocationStatDictionary => {
  const statDictionaryForOneLocation: LoadedLocationStatDictionary = {};
  Object.entries(dictionary).forEach(([id, statsByLocation]) => {
    statDictionaryForOneLocation[id] = statsByLocation[
      Object.keys(statsByLocation)[0] as string
    ] as LoadedStat;
  });
  return statDictionaryForOneLocation;
};

/**
 * Get all loaded stats for a given location from a dictionary of loaded stats
 *
 * @param locationId
 * @param dictionary
 *
 * @returns a dictionary of stat identifiers to loaded stats for the given location
 *
 * @see LoadedLocationStatDictionaryByLocation
 * @see LoadedLocationStatDictionary
 */
export const getLoadedStatsForLocation = (
  locationId: string,
  dictionary?: LoadedLocationStatDictionaryByLocation
): LoadedStat[] => Object.values(getLoadedStatDictionaryForLocation(locationId, dictionary));

interface FetchAllSectionStatsParams {
  locationGeography?: MhcGeographyEnum;
  locationId: string;
  sections: TopicDashboardSectionConfig[];
  locationStatArgs?: CreateLoadedStatDictionary["locationStatArgs"];
}

/**
 * Fetch all stat identifiers for a given location from a list of sections
 *
 * @param params
 * @param params.locationGeography
 * @param params.locationId
 * @param params.sections
 * @param params.locationStatArgs
 * @returns a dictionary of loaded stats
 *
 * @see FetchAllSectionIdentifiersParams
 * @see LoadedLocationStatDictionaryByLocation
 *
 */
export const fetchStatsForAllSections = async ({
  locationGeography,
  locationId,
  sections,
  locationStatArgs
}: FetchAllSectionStatsParams): Promise<LoadedLocationStatDictionaryByLocation> => {
  // Collect all stat identifier configs from all sections and their subsections
  // Filter configs that aren't necessary for the current geography and remove duplicates
  const configsNeedingLoadedStats = without(configKeys, "mapConfig");
  const identifierConfigs = sections.flatMap(({ subSections }) => {
    const configs = subSections.flatMap((s) => {
      const c: TopicDashboardSubsectionTypeConfig | TopicDashboardSubsectionTypeConfig[] = [];
      Object.entries(s).forEach(
        ([k, v]) => configsNeedingLoadedStats.includes(k as ConfigKeys) && c.push(v)
      );
      return c as TopicDashboardSubsectionTypeConfig | TopicDashboardSubsectionTypeConfig[];
    });

    return compact(configs.flatMap((config) => getStatIdsFromConfigs(config, locationGeography)));
  });

  return createLoadedStatDictionary({ locationId, identifierConfigs, locationStatArgs });
};

export const getDataDatesOfLoadedStatDictionary = (dictionary: LoadedLocationStatDictionary) => {
  const latestDates: number[] = [];
  const updatedDates: number[] = [];
  Object.values(dictionary)
    .map(
      ({
        statIdentifier,
        lastUpdatedOn
      }): [number | undefined, (number | undefined)[] | undefined] => [
        getUtcDateFromString(lastUpdatedOn)?.getTime() as number,
        statIdentifier.attributions?.flatMap(
          ({ lastUpdatedOn }) => getUtcDateFromString(lastUpdatedOn)?.getTime() as number
        )
      ]
    )
    .forEach(([updated, last]) => {
      updated && updatedDates.push(updated);
      last?.length && latestDates.push(...compact(last));
    });
  const latestDate = Math.max(...latestDates) ?? 0;
  const updateDate = Math.max(...updatedDates) ?? 0;

  return [
    latestDate !== 0 ? new Date(latestDate).toUTCString() : null,
    updateDate !== 0 ? new Date(updateDate).toUTCString() : null
  ];
};
