import { TopicElementBaseRender } from "modules/Topics/types";

import { TopicStories } from "modules/Topics/components/TopicStories";

export const getStoriesElement = <P extends { storySlugs?: string[] | null | undefined }>(
  id: string
): TopicElementBaseRender<P> => ({
  id,
  render: ({ storySlugs, locationNotAvailable }) =>
    storySlugs && !locationNotAvailable && <TopicStories storySlugs={storySlugs} />
});
