import { ReactNode } from "react";
import { Card, CardProps, Stack, Typography } from "@mui/material";

interface OverviewInsetCardProps {
  title: string;
  overline: string;
  variant?: "primary" | "emphasis";
  sx?: CardProps["sx"];
  children?: ReactNode;
}

export const TopicOverviewInsetCard: React.FC<OverviewInsetCardProps> = ({
  title,
  overline,
  variant = "primary",
  sx = {},
  children
}) => {
  const cardSx = {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: variant === "emphasis" ? "#e5e0df" : "#d9dbdf",
    backgroundColor: variant === "emphasis" ? "#FFF9F8" : "#F0F3F8",
    p: 2.5,
    ...sx
  };
  const textColor = variant === "emphasis" ? "brandSecondary.main" : "";
  return (
    <Card elevation={0} sx={cardSx}>
      <Stack gap={1}>
        <Typography component="div" variant="body3" color={textColor}>
          {overline}
        </Typography>
        <Typography variant="h3">{title}</Typography>
        {children}
      </Stack>
    </Card>
  );
};
