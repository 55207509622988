import { ContentCard, ContentCardProps } from "layout/card/ContentCard";
import { ReportDatesProps } from "common/components/ReportDates/ReportDates";

export interface TopicOverviewProps {
  dates: ReportDatesProps;
  topicSlug: string;
}

export const TopicCard: React.FC<ContentCardProps> = ({ children, ...props }) => {
  return <ContentCard {...props}>{children}</ContentCard>;
};
