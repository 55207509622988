import groupBy from "lodash/groupBy";
import { getAllLocationsFromGeographies } from "graphqlApi/legacy/mhcClient";

import {
  MhcGeographyEnum,
  MhcLocationFragment,
  MhcLocationWithoutGeoJsonFragment
} from "graphqlApi/types";

import { InvestigateMapLocationReference } from "common/components/InvestigateMap/InvestigateMap";
import { getMapGeographyJson } from "../maps";

interface Args {
  selectableGeographies: MhcGeographyEnum[];
  mappableGeographies: MhcGeographyEnum[];
}

const fetchSupportedLocations = async ({ selectableGeographies, mappableGeographies }: Args) => {
  const [locations, mappableLocations] = await Promise.all([
    selectableGeographies.length
      ? getAllLocationsFromGeographies(selectableGeographies, {
          withGeoJson: false
        })
      : [],
    getAllLocationsFromGeographies(mappableGeographies, { withGeoJson: true })
  ]);

  const allLocations = [...locations, ...mappableLocations] as MhcLocationFragment[];
  const locationReference: InvestigateMapLocationReference = {};
  allLocations.forEach((l) => (locationReference[l.id] = l as MhcLocationWithoutGeoJsonFragment));

  return {
    locations: allLocations,
    mapGeographies: getMapGeographyJson(groupBy(mappableLocations, "geography")),
    locationReference
  };
};

export default fetchSupportedLocations;
