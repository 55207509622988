import uniqBy from "lodash/uniqBy";
import { getMhcGeoStat, getMhcStatIdentifier } from "graphqlApi/legacy/mhcClient";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  MhcAttributionFragment,
  MhcGeographyEnum,
  MhcStatIdentifier,
  MhcStatIdentifierFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";
import {
  IdentifierConfig,
  TopicDashboardInvestigateMap,
  TopicDashboardMapSectionConfig
} from "modules/Topics/util/elementHelpers/dashboard/types";

import { legacyCreateInvestigateMapProps } from "common/components/InvestigateMap/V2/util/createInvestigateMapProps/legacy/createInvestigateMapProps";
import { formatDateByGranularity } from "common/util/formatHelpers";
import { getLatestDateFromArray } from "common/util/getLatestDate";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import {
  InvestigateMapAvailableGeographies,
  InvestigateMapInvestigation,
  InvestigateMapLocationReference
} from "common/components/InvestigateMap/InvestigateMap";
import { getIdentifiersOfStatIdConfigs } from "./fetchSectionData";
import {
  getLoadedStatDictionaryForLocation,
  LoadedLocationStatDictionaryByLocation
} from "./fetchStatsForAllSections";

interface Props {
  availableGeographies?: InvestigateMapAvailableGeographies[];
  geography: MhcGeographyEnum;
  loadedStats?: LoadedLocationStatDictionaryByLocation;
  locationGeography: MhcGeographyEnum;
  locationId: string;
  locationReference?: InvestigateMapLocationReference;
  mapConfig: TopicDashboardMapSectionConfig | null;
  title?: string;
}

export const fetchMapData = async ({
  availableGeographies = [],
  geography,
  loadedStats = {},
  locationId,
  locationReference = {},
  mapConfig,
  title
}: Props): Promise<TopicDashboardInvestigateMap | null> => {
  const loadedStatsForLoc = getLoadedStatDictionaryForLocation(locationId, loadedStats);
  if (!mapConfig) return null;
  const {
    id = "",
    type,
    statIdConfigs = [],
    supportedGeographies,
    omitGeographies,
    selectedRangeForLegend,
    colorPalette = null,
    dataToInvestigateDescription = null,
    aboutTheDataContent,
    showAttributionsInAboutTheData
  } = mapConfig;

  const statIds = getIdentifiersOfStatIdConfigs(statIdConfigs);

  if (type === "investigation") {
    return {
      id,
      supportedGeographies,
      attributions: [],
      latestDate: "",
      props: await legacyCreateInvestigateMapProps({
        availableGeographies: supportedGeographies ?? [MhcGeographyEnum.State],
        locationId,
        stats: statIdConfigs.map((config) =>
          typeof config === "object" ? (config as IdentifierConfig) : { identifier: config }
        ),
        omitGeographies
      }),
      aboutTheDataContent: aboutTheDataContent ?? null,
      showAttributionsInAboutTheData: showAttributionsInAboutTheData ?? true
    };
  } else {
    const statIdentifiers: MhcStatIdentifierFragment[] =
      Object.values(loadedStatsForLoc).map(({ statIdentifier }) => statIdentifier) ??
      (await Promise.all(
        statIds.map(async (statId) => await getMhcStatIdentifier({ statId: statId as string }))
      ));
    const statIdentifierReference: { [id: string]: MhcStatIdentifier } = {};
    statIdentifiers.forEach((si) => (statIdentifierReference[si.id] = si as MhcStatIdentifier));
    const locationIds: string[] = [];
    const investigations: InvestigateMapInvestigation[] = await Promise.all(
      statIdentifiers.map(async ({ id: statId, name }, i) => {
        const data: InvestigateMapInvestigation["data"] = {};
        const lastUpdatedDates: DateFromApi[] = [];
        if (i === 0) {
          const { geographyStats } = await getMhcGeoStat({ geography, statId });
          geographyStats.forEach(
            ({
              lastValue,
              location,
              lastUpdatedOn = null,
              granularity = MhcTimeSeriesGranularityEnum.Year
            }) => {
              locationIds.push(location.id);
              lastUpdatedDates.push(lastUpdatedOn);
              if (typeof lastValue === "number") {
                let date = "";
                if (lastUpdatedOn) {
                  date =
                    formatDateByGranularity({
                      value: getUtcDateFromString(lastUpdatedOn),
                      granularity
                    }) ?? "";
                }
                data[location.id] = {
                  value: lastValue,
                  date
                };
              }
            }
          );
        }

        return {
          value: statId,
          title: name,
          granularity: MhcTimeSeriesGranularityEnum.Year,
          data,
          latestDate: getLatestDateFromArray(lastUpdatedDates),
          loaded: i === 0,
          statId,
          selectedRangeForLegend: selectedRangeForLegend
            ? selectedRangeForLegend(statId)
            : "upto_fifty"
        };
      })
    );

    const attributions: MhcAttributionFragment[] = uniqBy(
      statIdentifiers.map(({ attributions }) => attributions as MhcAttributionFragment[]).flat(),
      "id"
    );

    const props: TopicDashboardInvestigateMap["props"] = {
      locationReference,
      investigations,
      availableGeographies,
      statIdentifierReference,
      defaultMapGranularity: "zip_code",
      defaultInvestigation: investigations[0]?.value ?? "",
      dataToInvestigateTitle: title ? `Select ${title} indicator` : "Select indicator",
      granularityTitle: "Select Map Type",
      useDataDate: true,
      dataToInvestigateDescription: dataToInvestigateDescription ?? "",
      locationIdReference: {
        [geography]: {
          loaded: true,
          locationIds
        }
      }
    };

    if (colorPalette) props.colorPalette = colorPalette;

    return {
      id,
      supportedGeographies,
      attributions,
      latestDate: getLatestDateFromArray(investigations.map(({ latestDate }) => latestDate)),
      props,
      aboutTheDataContent: aboutTheDataContent ?? null,
      showAttributionsInAboutTheData: showAttributionsInAboutTheData ?? true
    };
  }
};
