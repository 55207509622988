import isNil from "lodash/isNil";
import uniq from "lodash/uniq";

import { TopicDashboardSubsectionTypeConfig } from "../../elementHelpers/dashboard/types";
import { KpiCallback } from "./types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import {
  getAttributionsByStat,
  getAttributionsFromStats
} from "common/util/formatHelpers/statIdentifierHelpers";
import {
  determineRangeValueCaption,
  determineRangeValuePhrase
} from "common/util/formatHelpers/statIdentifierHelpers/ranges";

import { KpiGroupProps } from "common/components/KpiGroup";
import { geographySupportsSection, getIdentifiersOfStatIdConfigs } from "../fetchSectionData";
import {
  getLoadedStatDictionaryForLocation,
  LoadedLocationStatDictionaryByLocation
} from "../fetchStatsForAllSections";
import { fetchFourWeekAverageKpis } from "./fetchFourWeekAverageKpis";
import { fetchKpiProps } from ".";

export interface FetchKpiGroupProps {
  locationId: string;
  kpiGroupConfig: TopicDashboardSubsectionTypeConfig | null;
  locationGeography: MhcGeographyEnum;
  locationName: string;
  loadedStats?: LoadedLocationStatDictionaryByLocation;
}

export const fetchKpiGroupProps = async ({
  locationId,
  kpiGroupConfig,
  locationGeography,
  locationName,
  loadedStats
}: FetchKpiGroupProps): Promise<KpiGroupProps | null> => {
  if (
    !kpiGroupConfig ||
    !kpiGroupConfig?.statIdConfigs ||
    !geographySupportsSection(kpiGroupConfig.supportedGeographies, locationGeography)
  )
    return null;
  const scoreOrRatingRegexp = new RegExp(/SCORE|RATNG|RISK/g);
  const alternateValueCallback: KpiCallback = (value, identifier): string | null => {
    if (identifier?.match(scoreOrRatingRegexp)) {
      return determineRangeValuePhrase({
        value: parseInt(value as string),
        type: "rank"
      });
    }
    return value ? `${value}` : null;
  };
  const valueCaptionCallback: KpiCallback = (value, identifier) => {
    if (value && identifier?.match(scoreOrRatingRegexp)) {
      // Very specific to climate change — we probably need a better way of handling this
      const range: [number, number] | undefined = identifier?.match(/NRI/g) ? [1, 4] : undefined;
      return determineRangeValueCaption(parseInt(value as string), range);
    }
    return null;
  };

  const loadedStatsForLocation = getLoadedStatDictionaryForLocation(locationId, loadedStats);
  const {
    statIdConfigs,
    statIdsWithTrend,
    loadRelatedStats = false,
    showAboutTheData = false,
    forceRelatedStatTitle = false,
    ignoreLoadedStats = false,
    ...groupProps
  } = kpiGroupConfig;
  const statsFourWeekAverage = uniq(
    getIdentifiersOfStatIdConfigs(
      statIdConfigs.filter((stat) => (typeof stat === "object" ? stat?.fourWeekAverage : false))
    )
  );
  const _statIds = [...new Set(getIdentifiersOfStatIdConfigs(statIdConfigs))];
  const granularities: Record<string, MhcTimeSeriesGranularityEnum> = statIdConfigs.reduce(
    (prev, statConfig) => {
      if (typeof statConfig === "object") {
        if (!isNil(statConfig.granularity))
          return {
            ...prev,
            [statConfig.identifier]: statConfig.granularity
          };
      }
      return prev;
    },
    {} as Record<string, MhcTimeSeriesGranularityEnum>
  );
  const {
    kpis = [],
    latestDate = null,
    granularity = null
  } = await fetchKpiProps({
    loadedStats: ignoreLoadedStats ? undefined : loadedStats,
    statIds: _statIds,
    locationId,
    options: {
      alternateValueCallback,
      enhancements: { geography: locationName },
      granularities,
      forceRelatedStatTitle,
      includeTrend: false,
      loadRelatedStats,
      statIdsWithTrend,
      valueCaptionCallback,
      valueSize: (identifier: string) =>
        identifier?.match(scoreOrRatingRegexp) ? "medium" : "large"
    }
  });
  const {
    kpis: _kpis,
    latestDate: _latestDate,
    granularity: _granularity
  } = await fetchFourWeekAverageKpis({ statIds: statsFourWeekAverage, locationId, locationName });
  const attributions =
    showAboutTheData && loadedStats
      ? getAttributionsFromStats(Object.values(loadedStatsForLocation))
      : [];
  const attributionsByStat = getAttributionsByStat(Object.values(loadedStatsForLocation));
  return {
    ...groupProps,
    kpis: [...kpis, ..._kpis],
    latestDate,
    granularity: granularity ?? null,
    showAboutTheData,
    attributions,
    attributionsByStat
  };
};
