import isNil from "lodash/isNil";

import {
  MhcStatIdentifier,
  MhcStatIdentifierFragment,
  MhcStratumGroupEnum
} from "graphqlApi/types";

export const getGroupsFromStatIdentifier = (
  statIdentifier: MhcStatIdentifier | MhcStatIdentifierFragment
): MhcStratumGroupEnum[] => {
  return statIdentifier.stratificationGroups
    .map((sg) => sg.id)
    .filter((sgName) => !isNil(sgName)) as MhcStratumGroupEnum[];
};

export const getStratificationIds = (
  statIdentifier: MhcStatIdentifier | MhcStatIdentifierFragment
): string[] =>
  statIdentifier.stratificationGroups.flatMap(({ statIdentifiers }) =>
    statIdentifiers.flatMap(({ id }) => id)
  );
