import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export const xAxisLabelFormatterByGranularity = (granularity: MhcTimeSeriesGranularityEnum) => {
  const granularityName = (() => {
    switch (granularity) {
      case "day":
        return "Day";
      case "week":
        return "Weekly";
      case "month":
        return "Month";
      case "year":
      case "five_year_window":
        return "Year";
    }
  })();

  return granularity === "week" ? `Morbidity and Mortality Report (MMWR) Week` : granularityName;
};
