import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import { addDays } from "date-fns/addDays";

import { TopicPageTopic } from "../types";
import { StatIdTopicInfoDictionary } from "../util/fetchingFunctions/kpi/types";
import {
  MhcDateSeriesFragment,
  MhcLocationFragment,
  MhcStatIdentifierFragment,
  MhcTargetFragment,
  MhcTimeSeriesGranularityEnum,
  MhcTopicFragment
} from "graphqlApi/types";

import { calculateChangeFromTimeSeries } from "@/CPR/util/calculators";
import { getYearFromIsoString } from "common/util/helpers";
import { formatDateFromUTC, getUtcDateFromString } from "common/util/utcDateFromString";

import { IndicatorTableRow } from "common/components/IndicatorTable/IndicatorTable";
import { IndicatorTableContentWrapper } from "common/components/IndicatorTable/IndicatorTableContentWrapper";
import { KpiProps } from "common/components/KPI";
import ReportDates, { ReportDatesProps } from "common/components/ReportDates/ReportDates";
import { TopicContentCard } from "./TopicContentCard";

export interface TopicChildrenCardProps {
  kpis: KpiProps[] | null;
  topic: TopicPageTopic | null;
  subtitle?: string;
  location: MhcLocationFragment;
  loadStateData?: boolean;
  statIds?: string[];
  granularity: MhcTimeSeriesGranularityEnum;
  statIdTopicInfoDictionary?: StatIdTopicInfoDictionary;
  dates?: ReportDatesProps;
}
const TopicChildrenCard: React.FC<TopicChildrenCardProps> = ({
  topic,
  location,
  loadStateData,
  kpis,
  dates,
  granularity
}) => {
  const additionalData = (
    timeSeries?: MhcDateSeriesFragment["timeSeries"],
    granularity?: MhcTimeSeriesGranularityEnum
  ): Partial<IndicatorTableRow & { scopedTimeSeries?: MhcDateSeriesFragment["timeSeries"] }> => {
    const { dates = [], values = [] } = timeSeries ?? {};
    let startDate: string | number | undefined | Date = dates[0];
    let endDate: string | number | undefined = dates[dates.length - 1];
    if (!startDate || !endDate) return {};
    if (granularity === "week" && dates.length >= 3) {
      // add one day since the starting date is sunday not saturday
      // and it's calculated directly from the time series dates.
      startDate = addDays(dates[dates.length - 3] ?? "", 1);
      startDate = formatDateFromUTC(startDate, granularity) ?? "";
      endDate = formatDateFromUTC(endDate, granularity) ?? "";
    } else {
      startDate = `${getYearFromIsoString(startDate as string) ?? ""}`;
      endDate = `${getYearFromIsoString(endDate as string) ?? ""}`;
    }
    if (!startDate || !endDate) return {};

    const indexes: number[] = timeSeries?.dates
      .map((dateString, index) => {
        const start = getUtcDateFromString(startDate);
        const end = getUtcDateFromString(endDate);
        const date = getUtcDateFromString(dateString);
        if (isNil(start) || isNil(end) || isNil(date)) return null;
        if (date >= start && date <= end) return index;
        return null;
      })
      .filter((v) => !isNil(v)) as number[];

    const scopedValues: MhcDateSeriesFragment["timeSeries"]["values"] = [];
    const scopedDates: MhcDateSeriesFragment["timeSeries"]["dates"] = [];
    indexes.forEach((index) => {
      const v = values[index];
      const d = dates[index];
      if (!isNil(v) && !isNil(d)) {
        scopedValues.push(v);
        scopedDates.push(d);
      }
    });
    const scopedTimeSeries: MhcDateSeriesFragment["timeSeries"] = {
      ...timeSeries,
      dates: scopedDates,
      values: scopedValues
    };

    return {
      benchmark: calculateChangeFromTimeSeries(values, granularity),
      range: `(${startDate} - ${endDate})`,
      scopedTimeSeries
    };
  };

  const rows: IndicatorTableRow[] = useMemo(() => {
    const rows =
      kpis?.map(({ id, si, enhancement, statCaption, rawValue, percentSentenceProps }, i) => {
        const timeSeries = enhancement?.trendProps
          ?.timeSeries as MhcDateSeriesFragment["timeSeries"];
        const _topic = topic?.children?.find((c) =>
          c.statIdentifiers?.flatMap(({ id }) => id)?.includes(id ?? "")
        ) as MhcTopicFragment;
        const _additionalData = additionalData(timeSeries, enhancement?.granularity);
        return {
          id: id ?? `row-${i}`,
          topic: _topic
            ? (pick(_topic, ["name", "href"]) as IndicatorTableRow["topic"])
            : undefined,
          timeSeries: _additionalData.scopedTimeSeries
            ? _additionalData.scopedTimeSeries
            : timeSeries,
          caption: statCaption ?? "",
          si: si as MhcStatIdentifierFragment,
          locationValue: rawValue,
          target: enhancement?.targetProps?.target
            ? {
                value: enhancement.targetProps.target.value,
                object: (enhancement.targetProps.target as MhcTargetFragment) ?? null
              }
            : null,
          benchmarkImprovementType: si?.improvement,
          granularity: enhancement?.granularity ?? granularity,
          percentageChange: percentSentenceProps?.percentageChange ?? "",
          ..._additionalData
        };
      }) ?? [];
    return rows;
  }, [granularity, kpis, topic?.children]);

  const columnWidths = {
    topic: "25%",
    indicator: "25%",
    location: "10%",
    percent_change: "17%",
    trendline: "23%"
  };
  return (
    <TopicContentCard
      title="Key Data and Reports"
      subtitle={topic?.name ?? ""}
      headerChildren={dates ? <ReportDates granularity={granularity} {...dates} /> : undefined}
    >
      <Stack gap={4}>
        <Typography sx={{ maxWidth: "100ch" }}>
          Here, we highlight one key statistic from each topic. Click a table row to see more
          detailed data for the statistic. Or click on a link under &quot;Topic Page&quot; to view a
          statistic&apos;s corresponding topic page.
        </Typography>
        <IndicatorTableContentWrapper
          columnsToShow={Object.keys(columnWidths)}
          columnWidths={columnWidths}
          loadStateData={loadStateData}
          location={location}
          renderGhostColumns={false}
          rows={rows}
          tableId="topic-children"
          tableSorting={{
            field: "topic",
            direction: "asc"
          }}
          uiLocation={`${topic?.name ?? ""} - Key Data and Reports`}
        />
      </Stack>
    </TopicContentCard>
  );
};

export default TopicChildrenCard;
