import { ReactElement } from "react";
import { Typography } from "@mui/material";
import healthcareImage from "public/images/topics/lead/cdc-83YXJwzKwmY-unsplash.jpg";
// Images
import housingImage from "public/images/topics/lead/quino-al-fKc8aRgH_Jw-unsplash.jpg";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { TopicDashboardIntroSection } from "modules/Topics/components/TopicDashboardIntroSection";

export type TopicSectionContent = (sectionType?: string) => ReactElement;
export type TopicSectionContentDictionary = Record<string, TopicSectionContent>;

export const sectionContentDictionary: TopicSectionContentDictionary = {
  housing: () => (
    <TopicDashboardIntroSection
      intro={
        <ContentCardTextBlock>
          <Typography>
            These data explore risk factors associated with increased lead exposure related to
            housing such as housing age, property value, and renter occupied rates. The prevalence
            of lead-based paint and lead-based paint hazards increases with the age of the housing;
            nationwide, more than 93 percent of all homes with lead-based paint were built before
            1978, and 85.4 percent of homes with LBP were built before 1940.
          </Typography>
          <Typography>
            Data from the Delaware Division of Public Health show that 60-65% of children with
            elevated blood lead levels reside in renter-occupied homes. 57% of rental homes in
            Delaware were built before 1979, and 17% before 1950. Delaware&apos;s rental housing
            stock is oldest in New Castle County, where 68% of rental homes were built before 1978
            and 20% before 1950.
          </Typography>
        </ContentCardTextBlock>
      }
      image={{
        src: housingImage,
        alt: "Doorway in building where paint on walls is pealing",
        credit: { name: "Quino Al (Unsplash)", url: "//unsplash.com/photos/fKc8aRgH_Jw" }
      }}
    />
  ),
  "healthcare-access-and-resources": () => (
    <TopicDashboardIntroSection
      intro={
        <ContentCardTextBlock>
          <Typography>
            These data explore risk factors associated with increased lead exposure related to
            access to resources such as income, health insurance enrollment, and poverty rate.
            Houses in low-income areas, many of which have homes built before 1978, are more likely
            to contain lead-based paint and have pipes, faucets, and plumbing fixtures containing
            lead.
          </Typography>
        </ContentCardTextBlock>
      }
      image={{
        src: healthcareImage,
        alt: "Baby or toddler receiving an immunization from a doctor while being held by parent",
        credit: { name: "CDC (Unsplash)", url: "//unsplash.com/photos/83YXJwzKwmY" }
      }}
    />
  )
};
