import React from "react";

import { TopicDashboardSectionConfig } from "../util/elementHelpers/dashboard/types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { DataTable } from "common/components/DataTable";
import { LinkWithSmoothScroll } from "common/components/LinkWithSmoothScroll/LinkWithSmoothScroll";

interface Props {
  locationGeography?: MhcGeographyEnum;
  removeFirstItem?: boolean;
  sections: TopicDashboardSectionConfig[];
}

interface TableOfContentsRow {
  id: string;
  page: string;
  section: string;
}

export const TopicTableOfContents: React.FC<Props> = ({
  locationGeography,
  removeFirstItem = true,
  sections
}) => {
  const columns = [
    {
      display: "Report Sections",
      id: "section",
      render: ({ section, id }: TableOfContentsRow) => {
        return (
          <LinkWithSmoothScroll title={section} target={id} accountForOverlappingElements={true} />
        );
      }
    }
  ];

  const _sections: TopicDashboardSectionConfig[] = sections.filter((section) => {
    if (!locationGeography) return true;
    return section.supportedGeographies?.includes(locationGeography);
  });

  if (removeFirstItem) {
    // Remove the overview section since it contains the ToC
    _sections.shift();
  }

  const sectionTableProps = {
    selectedRowId: "",
    columns,
    data: _sections.map(({ title, id }) => ({ id, section: title ?? "", page: "" }))
  };

  return <DataTable {...sectionTableProps} />;
};
