import { Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

export interface DefaultOverviewProps {
  description?: string | null;
}

const DefaultOverview: React.FC<DefaultOverviewProps> = ({ description }: DefaultOverviewProps) => {
  if (isNil(description)) return <></>;
  const paragraphs = description.split("\n");
  if (paragraphs.length === 1) {
    return <Typography sx={{ maxWidth: "90ch" }}>{description}</Typography>;
  }
  return (
    <Stack direction="column" gap={2}>
      {paragraphs.map((paragraph, index) => {
        return (
          <Typography sx={{ maxWidth: "90ch" }} key={index}>
            {paragraph}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default DefaultOverview;
