import { getMhcMap, getMhcStatIdentifiersWithAvailableGeos } from "graphqlApi/legacy/mhcClient";

import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import {
  MhcFeatureCollection,
  MhcGeographyEnum,
  MhcMap,
  MhcStatIdentifier
} from "graphqlApi/types";
import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { removeStateFromAvailableGeos } from "../util";
import {
  convertLegendValuesToMinMax,
  identifierConfigsToMapLayerConfigs
} from "common/components/InvestigateMap/V2/util/converters";
import { logInfo } from "common/util/consoleHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

import { InvestigateMapV3Props } from "common/components/InvestigateMap/V2/InvestigateMapV3";

export interface CreateInvestigateMapPropsType<
  FeatureCollection extends object = MhcFeatureCollection
> {
  availableGeographies?: MhcGeographyEnum[];
  omitGeographies?: MhcGeographyEnum[];
  locationId?: string;
  stats: IdentifierConfig[];
  defaultGeography?: MhcGeographyEnum;
  overrideDateByStatMap?: InvestigateMapPropsV2<FeatureCollection>["overrideDateByStatMap"];
  defaultStatId?: string;
}

/**
 * Creates the basic configuration object for the investigate map
 *
 * @param props - configuration object
 * @param props.availableGeographies - (Optional) determines which geographies are available in case no stats are provided
 * @param props.omitGeographies - determines which geographies should be displayed in the investigate map
 * @param props.locationId - current selected location
 * @param props.stats - list of stat identifiers to be displayed in the investigate map
 * @param props.defaultGeography - initially selected and loaded geography (defaults to first geography in sorted list if not given)
 * @param props.overrideDateByStatMap -
 * @param props.defaultStatId - Default stat id to be selected
 *
 * @returns the basic configuration for the investigate map props
 *
 * @deprecated - Reason: Doesn't use the latest apollo client version use src/common/components/InvestigateMap/V2/util/CreateInvestigateMapProps/useCreateInvestigateMapProps.tsx
 */
export const legacyCreateInvestigateMapProps = async <
  FeatureCollection extends object = MhcFeatureCollection
>({
  availableGeographies: _availableGeographies,
  omitGeographies,
  locationId,
  stats,
  defaultGeography: _defaultGeography,
  defaultStatId,
  overrideDateByStatMap
}: CreateInvestigateMapPropsType<FeatureCollection>): Promise<InvestigateMapV3Props | null> => {
  let mapData: MhcMap | null = null;

  const statIds: string[] = stats?.map(({ identifier }) => identifier) ?? [];

  const loadedStatIdentifiers: MhcStatIdentifier[] = (await getMhcStatIdentifiersWithAvailableGeos({
    ids: statIds
  })) as MhcStatIdentifier[];

  const availableGeographies =
    _availableGeographies ??
    [...new Set(loadedStatIdentifiers.flatMap((stat) => stat.availableGeographies))].filter(
      (geo) => !omitGeographies?.includes(geo)
    );

  const defaultGeography =
    _defaultGeography ??
    sortGeographiesBySize(
      availableGeographies.filter((geo) =>
        omitGeographies ? !omitGeographies.includes(geo) : true
      )
    )[0] ??
    MhcGeographyEnum.ZipCode;

  if (process.env.DATA_LOADING_LOGGING_ENABLED) {
    logInfo("---------------------------------------------------------------------");
    logInfo("Fetching data for investigate Map");
    logInfo(" * statIds:");
    statIds.forEach((id) => logInfo(`   ${id}`));
    logInfo(` * default geography: ${defaultGeography as MhcGeographyEnum}`);
    logInfo("---------------------------------------------------------------------\n");
  }

  if (stats.length === 0) {
    mapData = await getMhcMap({
      geography: defaultGeography,
      layerConfigs: []
    });
  } else {
    mapData = await getMhcMap({
      geography: defaultGeography,
      layerConfigs: identifierConfigsToMapLayerConfigs({
        configs: stats ?? [],
        locationId
      })
    });
  }

  const mappedFeatures = {
    [defaultGeography]: mapData.featureCollection
  };
  const minMaxByStat =
    mapData.legend?.maxMinValues &&
    convertLegendValuesToMinMax({
      geography: defaultGeography,
      maxMinValues: mapData.legend?.maxMinValues
    });

  const _defaultStatId = defaultStatId ?? stats[0]?.identifier ?? "";

  return {
    defaultGeography,
    availableGeographies,
    omitGeographies: omitGeographies ?? [],
    defaultStatId: _defaultStatId,
    initialGeoJsonByGeography: mappedFeatures ?? {},
    initialMinMaxRecord: minMaxByStat ?? {},
    stats: removeStateFromAvailableGeos(loadedStatIdentifiers),
    statConfigs: stats ?? null,
    overrideDateByStatMap: overrideDateByStatMap ?? null
  };
};
