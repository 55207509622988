import compact from "lodash/compact";
import { getMhcDateSeries, getMhcLocationStat } from "graphqlApi/legacy/mhcClient";

import { Maybe, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";
import { StatIdConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { getMinMaxDates } from "common/components/charts/util/getMinMaxDates";
import { statIdentifierTarget } from "common/util/formatHelpers/statIdentifierHelpers";
import {
  LoadedDateSeries,
  LoadedStat,
  LoadedStatDictionary
} from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

import { IndicatorTableRow } from "../IndicatorTable";

interface Params {
  endsOn?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
  loadedStats?: LoadedStatDictionary;
  locationId: string;
  startsOn?: string;
  statIdConfigs: StatIdConfig[];
  fetchTrendAndComparisonData?: boolean;
  showWithNoData?: boolean;
}

const getStatIdAndGranularity = (
  statId: StatIdConfig,
  granularity: MhcTimeSeriesGranularityEnum | undefined
): [string, MhcTimeSeriesGranularityEnum | undefined] => {
  let _granularity: MhcTimeSeriesGranularityEnum | undefined =
    granularity || MhcTimeSeriesGranularityEnum.Year;
  let _statId: string;
  if (typeof statId === "object") {
    _granularity = statId.granularity;
    _statId = statId.identifier;
  } else {
    _statId = statId;
  }
  return [_statId, _granularity];
};

interface LocationDataArgs {
  locationId: string;
  statIdConfigs: StatIdConfig[];
  loadedStats?: LoadedStatDictionary;
  startsOn?: string;
  endsOn?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
}

interface DateSeriesParams {
  statId: string;
  locationId: string;
  startsOn?: string;
  endsOn?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
}

const fetchDateSeries = async ({
  statId,
  locationId,
  endsOn,
  startsOn,
  granularity
}: DateSeriesParams): Promise<LoadedDateSeries> => {
  const { timeSeries } = await getMhcDateSeries({
    locationId,
    statId,
    startsOn,
    endsOn,
    granularity
  });

  return timeSeries as LoadedDateSeries;
};

const fetchLocationData = async ({
  locationId,
  statIdConfigs,
  loadedStats,
  startsOn,
  endsOn,
  granularity
}: LocationDataArgs) => {
  return Promise.all(
    statIdConfigs.map(async (statIdConfig) => {
      const [_statId, _granularity] = getStatIdAndGranularity(statIdConfig, granularity);
      const loadedStat = (loadedStats && loadedStats[_statId]) ?? null;
      let relatedStatId;
      let loadedRelatedStat;
      if (
        typeof statIdConfig === "object" &&
        "relatedIdentifier" in statIdConfig &&
        statIdConfig.relatedIdentifier
      ) {
        relatedStatId = statIdConfig.relatedIdentifier;
        loadedRelatedStat = (loadedStats && loadedStats[relatedStatId]) ?? null;
      }

      const rowData = {
        timeSeries:
          loadedStat?.timeSeries ||
          (await fetchDateSeries({
            locationId,
            statId: _statId,
            startsOn,
            endsOn,
            granularity: _granularity
          })),
        locationStat:
          loadedStat ||
          (await getMhcLocationStat({
            locationId,
            statId: _statId,
            granularity: _granularity
          })),
        stateLocationStat: await getMhcLocationStat({
          locationId: "state",
          statId: _statId,
          granularity: _granularity
        }),
        nationalLocationStat: await getMhcLocationStat({
          locationId: "national",
          statId: _statId,
          granularity: _granularity
        }),
        relatedLocationStat: {} as LoadedStat
      };
      if (relatedStatId) {
        rowData.relatedLocationStat =
          loadedRelatedStat ||
          (await getMhcLocationStat({
            locationId,
            statId: relatedStatId,
            granularity: _granularity
          }));
      }
      return rowData;
    })
  );
};

export const calculateChangeFromTimeSeries = (timeSeries: Maybe<number>[]): number | undefined => {
  if (timeSeries.length < 2) {
    return undefined;
  }
  const first = timeSeries[0];
  const last = timeSeries[timeSeries.length - 1];
  if (first === undefined || last === undefined || first === null || last === null) {
    return undefined;
  }
  if (first === 0) {
    return 100;
  }
  return ((last - first) / first) * 100;
};

export const calculateChange = (
  value?: number | null,
  targetValue?: number | null
): number | undefined => {
  if (value === undefined || value === null || targetValue === undefined || targetValue === null) {
    return undefined;
  }
  return targetValue - value;
};

export const calculateRange = (dates: (number | string)[]): string | undefined => {
  if (dates.length < 2) {
    return undefined;
  }
  const [first, last] = getMinMaxDates(dates);
  if (first === undefined || last === undefined) {
    return undefined;
  }
  const startDate = new Date(first);
  const endDate = new Date(last);
  return `(${startDate.getFullYear()} - ${endDate.getFullYear()})`;
};

export const fetchDataForTable = async ({
  locationId,
  statIdConfigs,
  loadedStats,
  startsOn,
  endsOn,
  granularity,
  fetchTrendAndComparisonData = true,
  showWithNoData = false
}: Params) => {
  const rows: (IndicatorTableRow | null)[] = (
    await fetchLocationData({
      locationId,
      statIdConfigs,
      loadedStats,
      startsOn,
      endsOn,
      granularity
    })
  ).map(
    ({
      timeSeries,
      locationStat,
      stateLocationStat,
      nationalLocationStat,
      relatedLocationStat
    }) => {
      // Do not show row if no value
      const { statIdentifier, lastValue, lastUpdatedOn, percentageChange, sentence } = locationStat;
      if (lastValue === null && !showWithNoData) return null;
      const { dates = [], values = [] } = timeSeries as LoadedDateSeries;
      const benchmark = calculateChangeFromTimeSeries(values);
      const range = calculateRange(dates as string[]);
      const target = statIdentifierTarget(statIdentifier);
      const row: IndicatorTableRow = {
        id: "",
        si: statIdentifier,
        latestDate: lastUpdatedOn ?? null,
        timeSeries: fetchTrendAndComparisonData ? timeSeries : null,
        target: target ? { value: target.value, object: target } : null,
        percentageChange,
        statSentence: sentence,
        locationStat
      };
      if (statIdentifier.improvement !== undefined) {
        row.benchmarkImprovementType = statIdentifier.improvement;
      }
      if (typeof benchmark === "number") {
        row.benchmark = benchmark;
      }
      if (typeof range === "string") {
        row.range = range;
      }
      if (typeof lastValue === "number") {
        row.locationValue = lastValue;
      }
      if (typeof stateLocationStat.lastValue === "number") {
        row.stateValue = stateLocationStat.lastValue;
      }
      if (typeof nationalLocationStat.lastValue === "number") {
        row.nationalValue = nationalLocationStat.lastValue;
      }
      if (relatedLocationStat) {
        row.relatedValue = relatedLocationStat.lastValue ?? null;
        row.relatedSi = relatedLocationStat.statIdentifier ?? null;
      }

      return row;
    }
  );
  return compact(rows);
};
