import { ReactElement, ReactNode } from "react";

import { TopicDashboardSubSectionData } from "../util/elementHelpers/dashboard/types";

import { TopicSectionContentDictionary } from "../util/elementHelpers/Lead/risk_factors/sectionContentDictionary";

import { ImageProps } from "common/components/Image";
import ReportDates, { ReportDatesProps } from "common/components/ReportDates/ReportDates";
import { TopicDashboardData } from "modules/Topics/components/TopicDashboardData";
import { TopicContentCard } from "./TopicContentCard";
import { TopicDashboardIntroSection } from "./TopicDashboardIntroSection";

export interface TopicDashboardSectionComponentProps {
  currentTopicSlug?: string;
  id: string;
  title: string;
  subtitle?: string;
  intro?: ReactElement;
  locationName?: string;
  dates: ReportDatesProps;
  sectionContentDictionary?: TopicSectionContentDictionary;
  subSections?: TopicDashboardSubSectionData[];
  introSupportingContent?: ReactElement | null;
  image?: {
    src: string | StaticImageData;
    alt: string;
    credit: ImageProps["credit"];
  };
  children?: ReactNode;
}

export const TopicDashboardSection: React.FC<TopicDashboardSectionComponentProps> = ({
  id,
  title,
  subtitle,
  intro = null,
  locationName,
  dates,
  sectionContentDictionary,
  subSections,
  image,
  introSupportingContent,
  children,
  currentTopicSlug
}) => {
  return (
    <TopicContentCard
      title={title}
      subtitle={subtitle || locationName}
      headerChildren={
        dates ? <ReportDates {...dates} updatedDateText="Most recent update" /> : undefined
      }
      id={id}
    >
      {intro && (
        <TopicDashboardIntroSection
          intro={intro}
          supportingContent={introSupportingContent}
          image={image}
        />
      )}
      {subSections?.map((section) => (
        <TopicDashboardData
          section={section}
          key={section.id}
          locationName={locationName}
          sectionContentDictionary={sectionContentDictionary}
          currentTopicSlug={currentTopicSlug}
        />
      ))}
      {children}
    </TopicContentCard>
  );
};
