import { max } from "date-fns/max";

import { MhcAttributionFragment, MhcStatIdentifier } from "graphqlApi/types";

import { getUtcDateFromString } from "./utcDateFromString";

interface AttributionListFromLoadedSIsProps {
  loadedStatIdentifiers: { [id: string]: MhcStatIdentifier };
  idsForExpectedAttributions: string[];
}

export const attributionListFromLoadedSIs = ({
  loadedStatIdentifiers,
  idsForExpectedAttributions
}: AttributionListFromLoadedSIsProps): MhcAttributionFragment[] => {
  const attributions: MhcAttributionFragment[] = [];
  idsForExpectedAttributions.forEach((id) => {
    const statId = loadedStatIdentifiers[id];
    if (statId !== undefined && statId.attributions && statId.attributions.length > 0) {
      statId.attributions.forEach((attribution) => {
        attributions.push(attribution as MhcAttributionFragment);
      });
    }
  });

  return attributions;
};

export const getMaxDateFromAttributions = (
  attributions: MhcAttributionFragment[] = [],
  dontParse?: boolean
): Date | undefined => {
  if (!attributions.length) return;
  const attributionsWithDates = attributions.filter(
    (a) => a?.lastUpdatedOn && typeof a?.lastUpdatedOn === "string" && a?.lastUpdatedOn !== null
  );
  if (!attributions?.length || !attributionsWithDates?.length) return undefined;
  const returnValue = max(
    attributionsWithDates
      .map(({ lastUpdatedOn }) =>
        dontParse === true
          ? lastUpdatedOn
            ? new Date(lastUpdatedOn)
            : null
          : getUtcDateFromString(lastUpdatedOn)
      )
      .filter((d) => d !== null) as (number | Date)[]
  );
  return returnValue;
};
