import { TopicDashboardSectionConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { section as leadHousingSection } from "./testing_coverage_and_housing/config";
import { section as leadVulnerableCommunitiesSection } from "./testing_coverage_and_vulnerable_communities/config";
import { overviewSection } from "./overview";
import { section as riskFactorsSection } from "./risk_factors";
import { leadTestingRatesSection } from "./testing_rates_and_results";

const leadSections: TopicDashboardSectionConfig[] = [
  overviewSection,
  leadTestingRatesSection,
  riskFactorsSection,
  leadHousingSection,
  leadVulnerableCommunitiesSection,
  {
    id: "additional-information",
    title: "Additional Information",
    subSections: []
  }
];

export default leadSections;
