import capitalize from "lodash/capitalize";

import { MhcStatIdentifierFragment } from "graphqlApi/types";

type Stat = Pick<MhcStatIdentifierFragment, "ageAdjusted" | "isPercent" | "statType">;
export const statTypeName = ({ ageAdjusted, isPercent, statType }: Stat) => {
  if (ageAdjusted === true) {
    return "Age-adjusted Rate";
  }
  if (isPercent === true) {
    return "Percentage";
  }
  return capitalize(statType);
};
