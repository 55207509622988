import {
  getMhcCategories,
  getMhcPage,
  getMhcPages,
  getMhcTopic
} from "graphqlApi/legacy/mhcClient";

import { MhcTopicCategoryEnum, PageQueryVariables, TopicQueryVariables } from "graphqlApi/types";

import { MhcTopicCategories } from "layout/SideNav";
import { processTopics } from "../processTopics";

/**
 * @param locationId - the currently selected location id
 *
 * @returns a dictionary of the side nav items
 *
 * @deprecated Use src/common/util/fetchTopics/index.ts instead
 */
export const loadSideNavItems = async (locationId = "state"): Promise<MhcTopicCategories> => {
  const allowedCategories = [
    MhcTopicCategoryEnum.AllTopics,
    MhcTopicCategoryEnum.Portals,
    MhcTopicCategoryEnum.DataDownload,
    MhcTopicCategoryEnum.Reference
  ];
  const pages = await getMhcPages({
    categories: allowedCategories
  });
  const categories = await getMhcCategories({});

  return processTopics({ locationId, pages, categories, allowedCategories });
};

export const loadCurrentTopic = async ({ slug, locationId }: TopicQueryVariables) =>
  await getMhcTopic({ slug, locationId });

export const loadCurrentTopicPage = async ({ slug, locationId }: PageQueryVariables) =>
  await getMhcPage({ slug, locationId });
