import { ReactElement } from "react";
import { Grid } from "@mui/material";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { DEFAULT_IMAGE_SIZES } from "../constants";

import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import Image from "common/components/Image";
import { TopicDashboardSectionComponentProps } from "./TopicDashboardSection";

interface Props extends Pick<TopicDashboardSectionComponentProps, "image" | "intro"> {
  mb?: number;
  supportingContent?: ReactElement | null;
}

export const TopicDashboardIntroSection: React.FC<Props> = ({
  image,
  supportingContent = null,
  intro,
  mb = 4
}) => (
  <ContentCardSubSection sx={{ mb }}>
    <Grid container spacing={DEFAULT_COLUMN_SPACING}>
      <Grid item xs={12} lg={6}>
        <ContentCardTextBlock>{intro}</ContentCardTextBlock>
      </Grid>
      <Grid item xs={12} lg={6} sx={{ flex: 1 }}>
        {image && (
          <Image
            alt={image.alt}
            src={image.src}
            objectFit="cover"
            sizes={DEFAULT_IMAGE_SIZES}
            style={{ maxHeight: 200 }}
            credit={image.credit}
          />
        )}
        {supportingContent}
      </Grid>
    </Grid>
  </ContentCardSubSection>
);
