import {
  TopicDashboardBivariateAnalysis,
  TopicDashboardBivariateAnalysisConfig
} from "../elementHelpers/dashboard/types";
import { MhcGeographyEnum, MhcLocationFragment, MhcStatIdentifier } from "graphqlApi/types";

import { bivariateSectionLoadDataPoints } from "common/components/BivariateSection/util/bivariateSectionLoadDataPoints";
import { DEFAULT_BIVARIATE_COLOR_MAP } from "common/components/BivariateSection/util/colors";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";

import { BivariateMapProps } from "common/components/BivariateSection/BivariateMap";
import {
  InvestigateMapAvailableGeographies,
  InvestigateMapLocationReference
} from "common/components/InvestigateMap/InvestigateMap";
import { getIdentifiersOfStatIdConfigs } from "./fetchSectionData";
import fetchSupportedLocations from "./fetchSupportedLocations";

interface Params {
  config: TopicDashboardBivariateAnalysisConfig;
  location: MhcLocationFragment;
  locationGeography: MhcGeographyEnum;
  locationReference?: InvestigateMapLocationReference;
  mapGeographies?: InvestigateMapAvailableGeographies[];
}
/**
 * Loads data and creates props for bivariate analysis components
 *
 * @param params
 * @param params.config - The config for the bivariate analysis
 * @param params.location - The location to use for the analysis
 * @param params.locationReference - Dictionary of locations to include in the map
 * @param params.mapGeographies - The geographies to include in the map
 * @param params.locationGeography - The geography of the location
 *
 * @returns Props necessary to render bivariate components (map, slider, etc)
 */
export const fetchBivariateAnalysis = async ({
  config,
  location,
  locationReference,
  mapGeographies,
  locationGeography
}: Params): Promise<TopicDashboardBivariateAnalysis> => {
  const {
    bivariateValueComparisonName,
    bivariateValueComparisonOtherValueName,
    bottomLeftTitle = "",
    geography,
    id = "",
    quadrants,
    sliderProps,
    statLoadingVersion = 1,
    title,
    titles,
    topRightTitle = "",
    useYAxisForSelectedValue = false,
    xStatIdConfig,
    yStatIdConfig,
    startDate
  } = config;

  // TODO: Do not load all locations for a geography if we only need data for a single location

  // Handle situation where locationReference isn't provided
  let loadedLocationReference = locationReference ?? {};
  let loadedMapGeographies = mapGeographies ?? [];
  if (Object.values(loadedLocationReference).length === 0) {
    const supportedLocationsData = await fetchSupportedLocations({
      selectableGeographies: [],
      mappableGeographies: [geography]
    });
    loadedLocationReference = supportedLocationsData.locationReference;
    loadedMapGeographies = supportedLocationsData.mapGeographies;
  }

  let _locationReference = { ...loadedLocationReference };
  let locationIds = Object.values(_locationReference)
    .filter((l) => l.geography === config.geography)
    .map((l) => l.id);

  if (loadedMapGeographies.map(({ geography }) => geography).includes(locationGeography)) {
    locationIds = locationIds.filter((id) => id === location.id);
    _locationReference = {
      [location.id as string]: _locationReference[location.id] as MhcLocationFragment
    };
  }

  const {
    data: valueMap,
    loadedStatIdentifiers: identifierMap,
    latestXYDataDates,
    granularities
  } = await bivariateSectionLoadDataPoints({
    xStatIdConfig,
    yStatIdConfig,
    locationIds,
    geography,
    statLoadingVersion,
    startDate
  });
  const [xStatId = "", yStatId = ""] = getIdentifiersOfStatIdConfigs([
    xStatIdConfig,
    yStatIdConfig
  ]) as string[];

  if (Object.values(identifierMap).length === 0) throw new Error("No loaded identifiers found");
  const yValueOptions = getValueOptionsFromSi(identifierMap[yStatId] as MhcStatIdentifier);
  const xValueOptions = getValueOptionsFromSi(identifierMap[xStatId] as MhcStatIdentifier);
  const yAxisRange = {
    min: formatValueByUnit({
      value: 0,
      ...getValueOptionsFromSi(identifierMap[yStatId] as MhcStatIdentifier)
    }),
    max: formatValueByUnit({
      value: Math.max(...Object.values(valueMap).map(({ yAxis }) => yAxis)),
      ...yValueOptions
    })
  };
  const xAxisRange = {
    min: formatValueByUnit({
      value: 0,
      ...getValueOptionsFromSi(identifierMap[xStatId] as MhcStatIdentifier)
    }),
    max: formatValueByUnit({
      value: Math.max(...Object.values(valueMap).map(({ xAxis }) => xAxis)),
      ...xValueOptions
    })
  };

  const mapProps: Partial<BivariateMapProps> = {
    granularities,
    latestXYDataDates,
    xAxisResult: {
      nameOfIndicator: identifierMap[xStatId]?.name ?? "",
      description: (identifierMap[xStatId]?.statCaption || identifierMap[xStatId]?.unitLabel) ?? "",
      statIdentifier: identifierMap[xStatId],
      index: 0
    },
    yAxisResult: {
      nameOfIndicator: identifierMap[yStatId]?.name ?? "",
      description: (identifierMap[yStatId]?.statCaption || identifierMap[yStatId]?.unitLabel) ?? "",
      statIdentifier: identifierMap[yStatId],
      index: 0
    },
    locationReference: loadedLocationReference,
    geoJSON: (loadedMapGeographies[0] as InvestigateMapAvailableGeographies).geoJSON,
    valueMap
  };

  return {
    id,
    title,
    // eslint-disable-next-line
    // @ts-ignore - TODO: fix this — I think there's a conflict between the type of from the schema and the fragment
    attributions: Object.values(identifierMap).flatMap((si) =>
      si && "attributions" in si ? si.attributions : []
    ),
    props: {
      sviSliderProps: sliderProps ? sliderProps(locationGeography) : null,
      heatmapColors: DEFAULT_BIVARIATE_COLOR_MAP,
      selectedLocation: location,
      useYAxisForSelectedValue,
      bivariateValueComparisonName,
      bivariateValueComparisonOtherValueName,
      squareProps: {
        topRightTitle,
        bottomLeftTitle,
        xAxisTitle: identifierMap[xStatId]?.name ?? "",
        xAxisRange,
        yAxisTitle: identifierMap[yStatId]?.name ?? "",
        yAxisRange,
        xAxisTitleUnit:
          (identifierMap[xStatId]?.subtitle || identifierMap[xStatId]?.description) ?? "",
        yAxisTitleUnit:
          (identifierMap[yStatId]?.subtitle || identifierMap[yStatId]?.description) ?? "",
        xCategories: quadrants.x,
        yCategories: quadrants.y,
        ...titles,
        showValueRangesInAxes: true
      },
      mapProps
    }
  };
};
