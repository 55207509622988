import { IdentifierConfig, TopicDashboardSubsectionConfig } from "../../dashboard/types";
import { BivariateQuadrant } from "common/components/BivariateSection/util/types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { DEFAULT_BIVARIATE_COLOR_MAP } from "common/components/BivariateSection/util/colors";

import { LEAD_TESTING_IDENTIFIERS } from "../testing_rates_and_results/subSections";

const structureStatId: IdentifierConfig = {
  identifier: "STRUCTURE_BUILT_PRE_1970_CENSUS_PERCENT",
  granularity: MhcTimeSeriesGranularityEnum.Year
};
const testingStatId = {
  identifier: (LEAD_TESTING_IDENTIFIERS[0] as IdentifierConfig).identifier,
  granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
};

export const testingQuadrants: BivariateQuadrant[] = [
  {
    min: 0,
    max: 12
  },
  {
    min: 12,
    max: 24
  },
  {
    min: 24,
    max: 36
  },
  {
    min: 36
  }
];

const buildingAgeQuadrants: BivariateQuadrant[] = [
  {
    min: 0,
    max: 17
  },
  {
    min: 17,
    max: 38
  },
  {
    min: 38,
    max: 74
  },
  {
    min: 74
  }
];

export const subSections: TopicDashboardSubsectionConfig[] = [
  {
    id: "testing-and-housing-age",
    bivariateAnalysisConfig: {
      title: "Assessing Housing Stock Age in Vulnerable Communities",
      bivariateValueComparisonName: "percent of structures built before 1970",
      bivariateValueComparisonOtherValueName: "lead testing rate",
      geography: MhcGeographyEnum.CensusTract,
      supportedGeographies: [
        MhcGeographyEnum.State,
        MhcGeographyEnum.County,
        MhcGeographyEnum.ZipCode,
        MhcGeographyEnum.CensusTract
      ],
      xStatIdConfig: testingStatId,
      yStatIdConfig: structureStatId,
      topRightTitle: "More testing, less old housing",
      bottomLeftTitle: "Less testing, more old housing",
      quadrants: {
        x: testingQuadrants,
        y: buildingAgeQuadrants
      },
      useYAxisForSelectedValue: true,
      titles: {
        xAxisLabels: ["Lower testing rates", "", "", "Higher testing rates"],
        yAxisMinTitle: "Less old housing",
        yAxisMaxTitle: "More old housing"
      },
      sliderProps: (geography) =>
        geography === MhcGeographyEnum.CensusTract
          ? {
              leftSubtitle: "Less old housing",
              rightSubtitle: "More old housing",
              centerSubtitle: "",
              sliderValueTitle: "Percent of housing units built prior to 1970",
              leftLabel: "0%",
              leftValue: 0,
              rightLabel: "100%",
              rightValue: 100,
              barHeight: 30,
              width: "100%",
              fillDirection: "right_to_left",
              sectionColors: Object.values(DEFAULT_BIVARIATE_COLOR_MAP).reverse()
            }
          : null
    }
  }
];
