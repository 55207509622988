import { Grid } from "@mui/material";

import { dataStories } from "common/content/dataStories";

import { ContentCard } from "layout/card/ContentCard";
import DataStoryGrid from "common/components/Stories/DataStoryGrid";

interface Props {
  storySlugs: string[];
}

export const TopicStories: React.FC<Props> = ({ storySlugs = [] }) => {
  const stories = dataStories.filter(({ slug }) => slug && storySlugs.includes(slug));
  return (
    <ContentCard title="Investigative Stories">
      <Grid container spacing={4}>
        <DataStoryGrid stories={stories} showDescription={false} />
      </Grid>
    </ContentCard>
  );
};
