import { ReactNode } from "react";
import { AxisPlotLinesOptions } from "highcharts";

import { DateFromApi } from "graphqlApi/customTypes";
import { BivariateQuadrant } from "common/components/BivariateSection/util/types";
import { ChartProps } from "common/components/charts/Chart/types";
import { BaseInvestigateChartProps } from "common/components/charts/Investigate/types";
import { TimeScale } from "common/components/charts/types";
import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import {
  MhcAttributionFragment,
  MhcGeographyEnum,
  MhcLocation,
  MhcLocationFragment,
  MhcNote,
  MhcSeason,
  MhcStatIdentifierFragment,
  MhcTimeSeriesGranularityEnum,
  Scalars
} from "graphqlApi/types";

import { CreateLoadedStatDictionary } from "../../fetchingFunctions/util";
import { CreateInvestigateMapPropsType } from "common/components/InvestigateMap/V2/util/createInvestigateMapProps/createInvestigateMapProps";
import { StandardMapDataTableRow } from "common/components/InvestigateMap/V2/WithDataTable/util/geoJsonToDataForSelectedStat";

import { BivariateHeatmapProps } from "common/components/BivariateSection/BivariateHeatmap";
import { BivariateMapProps } from "common/components/BivariateSection/BivariateMap";
import {
  BivariateSectionProps,
  OmittedBivariateMapProps
} from "common/components/BivariateSection/BivariateSection";
import { IndicatorInvestigateChartProps } from "common/components/charts/Investigate/IndicatorInvestigateChart";
import { LineChartProps } from "common/components/charts/LineChart";
import { ImageProps } from "common/components/Image";
import { IndicatorTableProps } from "common/components/IndicatorTable/IndicatorTable";
import { IndicatorTableContentWrapperProps } from "common/components/IndicatorTable/IndicatorTableContentWrapper";
import { InvestigateMapProps } from "common/components/InvestigateMap/InvestigateMap";
import {
  MapColorPalette,
  PossibleRanges
} from "common/components/InvestigateMap/styles/investigateMapStyles";
import { InvestigateMapV3Props } from "common/components/InvestigateMap/V2/InvestigateMapV3";
import { InvestigateMapWithDataTableProps } from "common/components/InvestigateMap/V2/WithDataTable/InvestigateMapWithDataTable";
import { KpiGroupProps } from "common/components/KpiGroup";
import { LoadedLocationStatDictionary } from "../../fetchingFunctions/fetchStatsForAllSections";
import { AdditionalPageData } from "../Covid/getData";

export type StatAttributions = MhcStatIdentifierFragment["attributions"];
export type TopicDashboardImages = Record<string, ImageProps>;

/**  */
export interface IdentifierConfig {
  identifier: string;
  granularity?: MhcTimeSeriesGranularityEnum;
  /** Need/Load stat value and associated date  */
  needStat?: boolean;
  /** Need/Load date series  */
  needSeries?: boolean;
  /** Need/Load stratifications  */
  needStratifications?: boolean;
  /** Load for specific location */
  forLocationId?: string;
  /** Load stat identifier and stats for a related stat identifier (e.g. COUNT) */
  relatedIdentifier?: string;
  group?: string;
  startsOn?: string | null;
  endsOn?: string | null;
  fourWeekAverage?: boolean;
}

export type StatIdConfig = string | Scalars["ID"] | IdentifierConfig;

type ChartType =
  | "line"
  | "indicator-investigation"
  | "multi-investigation"
  | "bar"
  | "location-comparison";

// Config given to fetchers to determine data
export interface TopicDashboardSubsectionTypeConfig {
  id?: string;
  ignoreLoadedStats?: boolean;
  attributions?: StatAttributions;
  title?: string;
  introText?: string;
  loadRelatedStats?: boolean;
  statIdConfigs?: StatIdConfig[];
  statIdsWithTrend?: string[];
  supportedGeographies?: MhcGeographyEnum[];
  aboutTheDataContent?: AboutTheDataContent;
  showAboutTheData?: boolean;
  showAttributionsInAboutTheData?: boolean;
  forceRelatedStatTitle?: boolean;
}
export interface TopicDashboardMapSectionConfig extends TopicDashboardSubsectionTypeConfig {
  type?: "investigation" | "bivariate";
  selectedRangeForLegend?: (id: string) => PossibleRanges;
  colorPalette?: MapColorPalette | null;
  dataToInvestigateDescription?: string | null;
  omitGeographies?: CreateInvestigateMapPropsType["omitGeographies"];
}

export type TopicDashboardMapSectionV2Config = CreateInvestigateMapPropsType &
  Pick<
    InvestigateMapPropsV2,
    | "overrideDateByStatMap"
    | "allowGeographyChange"
    | "customInvestigationLabel"
    | "colorRangeName"
    | "fullWidthMap"
  > & {
    kpiGroupConfig?: TopicDashboardSubsectionTypeConfig;
    locationStatArgs?: CreateLoadedStatDictionary["locationStatArgs"];
  };

export interface TopicDashboardBivariateAnalysisConfig extends TopicDashboardSubsectionTypeConfig {
  bivariateValueComparisonName: string;
  bivariateValueComparisonOtherValueName: string;
  geography: MhcGeographyEnum;
  xStatIdConfig: IdentifierConfig;
  yStatIdConfig: IdentifierConfig;
  topRightTitle: string;
  bottomLeftTitle: string;
  quadrants: {
    x: BivariateQuadrant[];
    y: BivariateQuadrant[];
  };
  titles: Pick<BivariateHeatmapProps, "xAxisLabels" | "yAxisMinTitle" | "yAxisMaxTitle">;
  useYAxisForSelectedValue?: boolean;
  sliderProps?: (geography: MhcGeographyEnum) => BivariateSectionProps["sviSliderProps"];
  statLoadingVersion?: number;
  startDate?: string;
}
export interface TopicDashboardChartSectionConfig extends TopicDashboardSubsectionTypeConfig {
  footnotes?: AdditionalInfoFootnotes;
  forceToMaxValue?: boolean;
  forLocation?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
  includeStratifications?: boolean;
  locations?: Array<{ id: string; name: string }>;
  overrideProps?: Partial<LineChartProps>;
  plotLines?: AxisPlotLinesOptions[];
  showAdditionalInfo?: boolean;
  stratificationsAsDropdown?: boolean;
  timeScale?: TimeScale;
  type?: ChartType;
  xAxisTitleText?: string;
  yAxisTitleText?: string;
}

export interface TopicDashboardTableSectionConfig extends TopicDashboardSubsectionTypeConfig {
  granularity?: MhcTimeSeriesGranularityEnum;
  showWithNoData?: boolean;
  props?: Omit<IndicatorTableProps, "location" | "rows">;
}

export type AboutTheDataContent = Array<{ title?: string; text: string }>;

export const configKeys = [
  "bivariateAnalysisConfig",
  "chartConfigs",
  "kpiGroupConfig",
  "mapConfig",
  "tableConfig"
] as const;
export type ConfigKeys = (typeof configKeys)[number];

export interface TopicDashboardSubsectionConfig {
  id: string;
  title?: string;
  bivariateAnalysisConfig?: TopicDashboardBivariateAnalysisConfig;
  chartConfigs?: TopicDashboardChartSectionConfig[];
  kpiGroupConfig?: TopicDashboardSubsectionTypeConfig;
  /**
   * @deprecated Use mapConfigV2 instead.
   */
  mapConfig?: TopicDashboardMapSectionConfig;
  mapConfigV2?: TopicDashboardMapSectionV2Config;
  tableConfig?: TopicDashboardTableSectionConfig;
  attributions?: StatAttributions;
}

export interface TopicDashboardSectionConfig extends TopicDashboardData {
  subSections: TopicDashboardSubsectionConfig[];
  supportedGeographies?: MhcGeographyEnum[];
}

// Data returned from fetchers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~

type AdditionalInfoFootnotes = string[];

export interface TopicDashboardData {
  id: string;
  title?: string;
  updatedDate?: string | number | null;
  latestDate?: DateFromApi;
  attributions?: StatAttributions;
  granularity?: MhcTimeSeriesGranularityEnum;
  loadedStats?: LoadedLocationStatDictionary;
  aboutTheDataContent?: AboutTheDataContent | null;
  showAttributionsInAboutTheData?: boolean;
  footnotes?: AdditionalInfoFootnotes;
}

export interface TopicDashboardChart {
  id: string;
  title: string;
  noData: boolean;
  props: ChartProps | LineChartProps | IndicatorInvestigateChartProps | BaseInvestigateChartProps;
  type: ChartType;
  showAdditionalInfo?: boolean;
  dataSources?: MhcAttributionFragment[];
  attributions?: StatAttributions;
}

export interface TopicDashboardChartGroup extends TopicDashboardData {
  charts: TopicDashboardChart[];
}

export type TopicInvestigateMapProps = Omit<
  InvestigateMapProps,
  | "mapGranularity"
  | "updateMapGranularity"
  | "selectedId"
  | "setSelectedId"
  | "selectedSi"
  | "setSelectedSi"
  | "setInvestigations"
>;

export interface TopicDashboardInvestigateMap extends TopicDashboardData {
  title?: string;
  supportedGeographies?: MhcGeographyEnum[];
  props: InvestigateMapV3Props | TopicInvestigateMapProps | null;
}

export interface TopicDashboardIndicatorTable extends TopicDashboardData {
  dataAvailable?: boolean;
  introText?: ReactNode;
  props: IndicatorTableContentWrapperProps;
}

interface TopicDashboardBivariateMapProps
  extends Omit<BivariateMapProps, OmittedBivariateMapProps | "featureColorFunction"> {
  featureColorFunction?: BivariateMapProps["featureColorFunction"];
}
export interface FetchedBivariateAnalysisProps extends Omit<BivariateSectionProps, "mapProps"> {
  mapProps: Partial<TopicDashboardBivariateMapProps> | null;
}
export interface TopicDashboardBivariateAnalysis extends TopicDashboardData {
  props: FetchedBivariateAnalysisProps;
}

export const SUB_SECTION_KEYS = [
  "bivariateAnalysis",
  "chartGroup",
  "indicatorTable",
  "kpiGroup",
  "map"
];

export interface TopicDashboardSubSectionData extends TopicDashboardData {
  bivariateAnalysis?: TopicDashboardBivariateAnalysis;
  chartGroup?: TopicDashboardChartGroup | null;
  indicatorTable?: TopicDashboardIndicatorTable | null;
  kpiGroup?: KpiGroupProps | null;
  map?: TopicDashboardInvestigateMap | null;
  mapV2?: TopicDashboardSubsectionTypeConfig &
    InvestigateMapWithDataTableProps<StandardMapDataTableRow>;
}

export type TopicDashboardDataGroup =
  | KpiGroupProps
  | TopicDashboardChartGroup
  | TopicDashboardInvestigateMap
  | (TopicDashboardData & InvestigateMapWithDataTableProps<StandardMapDataTableRow>)
  | TopicDashboardIndicatorTable
  | TopicDashboardBivariateAnalysis;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~

export interface TopicDashboardSectionProps {
  id: string;
  subSections: TopicDashboardSubSectionData[];
  title: string;
  latestDate?: DateFromApi;
  updatedDate?: string | number | null;
  granularity?: MhcTimeSeriesGranularityEnum;
  attributions?: StatAttributions;
}

export type DashboardElement = {
  sections?: TopicDashboardSectionProps[];
  locations?: (MhcLocationFragment | MhcLocation)[];
};

export type SeasonalDiseaseDashboardElement<A extends object = AdditionalPageData> = {
  sections?: TopicDashboardSectionProps[];
  locations?: (MhcLocationFragment | MhcLocation)[];
  season: MhcSeason | null;
  seasons: MhcSeason[];
  additionalData?: A;
  notes: MhcNote[];
};
