import { Box } from "@mui/material";

import { FcWithChildren } from "common/components/types";

export const StyledList: FcWithChildren<{ itemMargin?: number }> = ({
  children,
  itemMargin = 0.25
}) => (
  <Box
    component="ul"
    sx={{
      listStyleType: "circle",
      paddingLeft: 2,
      margin: 0,
      gap: 1,
      "> li": {
        paddingLeft: 0,
        marginBottom: itemMargin
      },
      "> li:last-of-type": {
        marginBottom: 0
      }
    }}
  >
    {children}
  </Box>
);
