import { getMhcLocationStat, getMhcMapDataWithStats } from "graphqlApi/legacy/mhcClient";

import { DateFromApi } from "graphqlApi/customTypes";
import { BivariatePoint } from "common/components/BivariateSection/util/types";
import {
  MhcGeographyEnum,
  MhcStatIdentifier,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";
import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { getLatestDateFromArray } from "common/util/getLatestDate";
import { getUtcDateFromString } from "common/util/utcDateFromString";
import { getIdentifiersOfStatIdConfigs } from "modules/Topics/util/fetchingFunctions/fetchSectionData";

interface Params {
  xStatIdConfig: IdentifierConfig;
  yStatIdConfig: IdentifierConfig;
  locationIds: string[];
  startDate?: string;
  geography?: MhcGeographyEnum;
  statLoadingVersion?: number;
}
export const bivariateSectionLoadDataPoints = async ({
  xStatIdConfig,
  yStatIdConfig,
  locationIds,
  startDate,
  geography,
  statLoadingVersion
}: Params) => {
  const data: { [id: string]: BivariatePoint } = {};
  const loadedStatIdentifiers: { [id: string]: MhcStatIdentifier } = {};
  const xDates: (DateFromApi | undefined)[] = [];
  const yDates: (DateFromApi | undefined)[] = [];
  const [xStatId = "", yStatId = ""] = getIdentifiersOfStatIdConfigs([
    xStatIdConfig,
    yStatIdConfig
  ]);

  let loadedGeoJson = null;

  if (geography && statLoadingVersion === 2) {
    loadedGeoJson = await getMhcMapDataWithStats({
      geographies: [geography],
      statIds: [xStatId, yStatId]
    });

    loadedGeoJson.forEach(({ id, mapData }) => {
      const [xlocationStat = null, ylocationStat = null] =
        mapData?.features?.[0]?.properties?.stats ?? [];
      if (!xlocationStat || !ylocationStat) {
        throw new Error("Missing stat data for location");
      }
      loadedStatIdentifiers[xStatId] ||= xlocationStat.statIdentifier as MhcStatIdentifier;
      loadedStatIdentifiers[yStatId] ||= ylocationStat.statIdentifier as MhcStatIdentifier;
      const xDate = getUtcDateFromString(xlocationStat.lastUpdatedOn);
      const yDate = getUtcDateFromString(ylocationStat.lastUpdatedOn);
      const startsOn = startDate ? getUtcDateFromString(startDate) : null;
      if (xDate && startsOn && yDate && xDate < startsOn && yDate < startsOn) {
        return;
      } else {
        xDates.push(xlocationStat.lastUpdatedOn);
        yDates.push(ylocationStat.lastUpdatedOn);
        if (
          typeof xlocationStat.lastValue === "number" &&
          typeof ylocationStat.lastValue === "number"
        ) {
          data[id] = {
            xAxis: xlocationStat.lastValue,
            yAxis: ylocationStat.lastValue
          };
        }
      }
    });
  } else {
    await Promise.all(
      locationIds.map(async (locationId) => {
        // TODO: Collect these queries into getMhcLocationStats
        const xlocationStat = await getMhcLocationStat({
          locationId,
          statId: xStatId,
          granularity: xStatIdConfig.granularity
        });
        const ylocationStat = await getMhcLocationStat({
          locationId,
          statId: yStatId,
          granularity: yStatIdConfig.granularity
        });
        if (loadedStatIdentifiers[xStatId] === undefined) {
          loadedStatIdentifiers[xStatId] = xlocationStat.statIdentifier as MhcStatIdentifier;
        }
        if (loadedStatIdentifiers[yStatId] === undefined) {
          loadedStatIdentifiers[yStatId] = ylocationStat.statIdentifier as MhcStatIdentifier;
        }
        const xDate = getUtcDateFromString(xlocationStat.lastUpdatedOn);
        const yDate = getUtcDateFromString(ylocationStat.lastUpdatedOn);
        const startsOn = startDate ? getUtcDateFromString(startDate) : null;
        if (xDate && startsOn && yDate && xDate < startsOn && yDate < startsOn) {
          return;
        } else {
          xDates.push(xlocationStat.lastUpdatedOn);
          yDates.push(ylocationStat.lastUpdatedOn);
          if (
            typeof xlocationStat.lastValue === "number" &&
            typeof ylocationStat.lastValue === "number"
          ) {
            data[locationId] = {
              xAxis: xlocationStat.lastValue,
              yAxis: ylocationStat.lastValue
            };
          }
        }
      })
    );
  }
  return {
    data,
    loadedStatIdentifiers,
    latestDataDate: getLatestDateFromArray([...xDates, ...yDates]),
    latestXYDataDates: {
      x: getLatestDateFromArray(xDates) ?? "",
      y: getLatestDateFromArray(yDates) ?? ""
    },
    granularities: {
      x: xStatIdConfig.granularity ?? MhcTimeSeriesGranularityEnum.Year,
      y: yStatIdConfig.granularity ?? MhcTimeSeriesGranularityEnum.Year
    }
  };
};
