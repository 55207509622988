import { IdentifierConfig, TopicDashboardSubsectionConfig } from "../../dashboard/types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { DEFAULT_BIVARIATE_COLOR_MAP } from "common/components/BivariateSection/util/colors";

import { testingQuadrants } from "../testing_coverage_and_housing/subSections";
import { LEAD_TESTING_IDENTIFIERS } from "../testing_rates_and_results/subSections";

const sviStatId: IdentifierConfig = {
  identifier: "SVI_OVERALL_RATING_CRUDE_RATE",
  granularity: MhcTimeSeriesGranularityEnum.Year
};
const testingStatId = {
  identifier: (LEAD_TESTING_IDENTIFIERS[0] as IdentifierConfig).identifier,
  granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
};

const sviQuadrants = [
  {
    min: 0,
    max: 0.25
  },
  {
    min: 0.25,
    max: 0.5
  },
  {
    min: 0.5,
    max: 0.75
  },
  {
    min: 0.75
  }
];

export const subSections: TopicDashboardSubsectionConfig[] = [
  {
    id: "testing-and-vulnerable-communities",
    bivariateAnalysisConfig: {
      title: "Assessing Lead Testing Coverage in Vulnerable Communities",
      bivariateValueComparisonName: "Social Vulnerability Index (SVI) score",
      bivariateValueComparisonOtherValueName: "lead testing rate",
      geography: MhcGeographyEnum.CensusTract,
      supportedGeographies: [
        MhcGeographyEnum.State,
        MhcGeographyEnum.County,
        MhcGeographyEnum.ZipCode,
        MhcGeographyEnum.CensusTract
      ],
      xStatIdConfig: testingStatId,
      yStatIdConfig: sviStatId,
      topRightTitle: "Less Vulnerable, More testing",
      bottomLeftTitle: "More Vulnerable, Less Testing",
      quadrants: {
        x: testingQuadrants,
        y: sviQuadrants
      },
      useYAxisForSelectedValue: true,
      titles: {
        xAxisLabels: ["Lower testing rates", "", "", "Higher testing rates"],
        yAxisMinTitle: "Less Vulnerable",
        yAxisMaxTitle: "More Vulnerable"
      },
      sliderProps: (geography) =>
        geography === MhcGeographyEnum.CensusTract
          ? {
              leftLabel: "0",
              rightLabel: "1",
              leftSubtitle: "Low",
              rightSubtitle: "High",
              centerSubtitle: "Moderate",
              sliderValueTitle: "SVI Score",
              leftValue: 0,
              rightValue: 1,
              barHeight: 30,
              width: "100%",
              fillDirection: "right_to_left",
              sectionColors: Object.values(DEFAULT_BIVARIATE_COLOR_MAP).reverse()
            }
          : null
    }
  }
];
