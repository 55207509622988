import React from "react";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { Skeleton, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import compact from "lodash/compact";
import times from "lodash/times";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatDateTime } from "common/util/date";

import { InfoIcon } from "../InfoIcon";

export const ReportDatesFallback = () => (
  <Stack>
    {times(2, (i) => (
      <Stack key={`report-dates-loading-skeleton-${i}`} flex={1} alignItems="flex-end">
        <Typography variant="body3">
          <Skeleton width={200} />
        </Typography>
      </Stack>
    ))}
  </Stack>
);

export interface ReportDatesProps {
  /*
    Associated with the report generation.
    This date should reflect when the page or PDF was rendered or downloaded.
  */
  generatedDate?: Date;
  generatedDateText?: string;
  /* Associated with the data,
     This date should match the date of the most recent data (locationStat)
     Default text: "Data are current as of"
     @see dataDateText
     @see MhcLocationStat
  */
  dataDate?: Date;
  dataDateText?: string;
  /*
    Associated with the data updates,
    This date should correspond to when we updated the location stats
    It is the most recent `lastUpdatedOn` of stat Identifier attributions
    (and therefore always comes after the other date)
    Default text: "Last updated on"
    @see updatedDateText
    @see MhcStatIdentifier
    @see MhcAttribution
  */
  updatedDate?: Date;
  updatedDateText?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
}

const ReportDates: React.FC<ReportDatesProps> = ({
  generatedDate,
  generatedDateText = "Report generated on",
  dataDate,
  dataDateText = "Data are current as of",
  updatedDate,
  updatedDateText = "Most recent dashboard update",
  granularity
}) => {
  const parsedGeneratedDate = formatDateTime(generatedDate);
  const parsedDataDate = formatDateTime(dataDate, granularity);
  const parsedUpdatedDate = formatDateTime(updatedDate, "day");
  // Data current as of February 9, 2023
  // Last updated February 10, 2023
  const nonNullCount = compact([parsedGeneratedDate, parsedDataDate, parsedUpdatedDate]).length;
  return (
    <Stack direction="row" gap={1} alignItems="flex-end">
      <Stack
        sx={{
          alignItems: {
            xs: "flex-start",
            md: "flex-end"
          },
          textAlign: { xs: "left", md: "right" },
          flex: { xs: 0, md: 1 }
        }}
      >
        {parsedGeneratedDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={{ color: grey[700], whiteSpace: "nowrap" }}
          >
            {generatedDateText} {parsedGeneratedDate}
          </Typography>
        )}
        {parsedDataDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={{ color: grey[700], whiteSpace: "nowrap" }}
          >
            {dataDateText} {parsedDataDate}
          </Typography>
        )}
        {parsedUpdatedDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={{ color: grey[700], whiteSpace: "nowrap" }}
          >
            {updatedDateText}: {parsedUpdatedDate}
          </Typography>
        )}
      </Stack>
      {nonNullCount > 1 && (
        <InfoIcon IconComponent={HelpOutlineOutlined}>
          <Stack direction="column" gap={1}>
            <Typography variant="body2">Why are these dates different?</Typography>
            <Typography variant="body2">
              To ensure data accuracy and quality, all data received by DPH is thoroughly reviewed
              before publishing.
            </Typography>
          </Stack>
        </InfoIcon>
      )}
    </Stack>
  );
};

export default ReportDates;
