import { MhcLocation, MhcLocationFragment } from "graphqlApi/types";

import { ContentCard } from "layout/card/ContentCard";
import { TopicMedia } from "../pages/items/DrugYouthRx";
import { TopicVideo } from "./TopicVideo";

export interface TopicVideosProps {
  media: TopicMedia;
  location?: MhcLocation | MhcLocationFragment;
}

export const TopicVideos: React.FC<TopicVideosProps> = ({ media, location }) => {
  if (media.length === 0) return null;
  return (
    <ContentCard title="Investigate Maps">
      {media.map(({ videos, statIdentifier }) =>
        videos?.map((video, i) => (
          <TopicVideo
            {...video}
            location={location}
            statIdentifier={statIdentifier}
            key={statIdentifier?.id ?? i}
          />
        ))
      )}
    </ContentCard>
  );
};
