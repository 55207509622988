import compact from "lodash/compact";
import every from "lodash/every";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import {
  IdentifierConfig,
  StatIdConfig,
  TopicDashboardChartSectionConfig
} from "../../elementHelpers/dashboard/types";
import { ChartableStat } from "./types";
import { MhcStatIdentifierFragment, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { ENTIRE_GROUP_NAME } from "./constants";
import { statTypeName } from "common/util/formatHelpers/statTypeName";

import { IndicatorInvestigateChartProps } from "common/components/charts/Investigate/IndicatorInvestigateChart";
import { LineChartProps, LineChartSeries } from "common/components/charts/LineChart";
import {
  LoadedLocationStatDictionary,
  LoadedLocationStatDictionaryByLocation,
  LoadedStat,
  LoadedStatDictionary
} from "../fetchStatsForAllSections";

export const determineTickInterval = (max?: number | null) => {
  if (!max || typeof max === "string") return 5;
  if (max < 1) return 0.25;
  const proposedInterval = Math.ceil(((max || 5) * 1.1) / 10);
  if (proposedInterval >= 5) {
    return proposedInterval;
  }
  const numberOfDigits = Math.round(max).toString().length - 1;
  if (numberOfDigits >= 5) return 10000;
  switch (numberOfDigits) {
    case 1:
      return 10;
    case 2:
      return 100;
    case 3:
      return 500;
    case 4:
      return 1000;
  }
};

/**
 * Create line chart series from location stats or location stat dictionary
 *
 * @param params
 * @param params.locationStatDictionary
 * @param params.locationStats
 * @param params.nameProp
 * @param params.locations
 *
 * @returns line chart series
 */
export const getSeriesFromLocationStats = ({
  locationStatDictionary,
  locationStats,
  nameProp = "fullName",
  locations
}: {
  locationStatDictionary?: LoadedLocationStatDictionary | LoadedLocationStatDictionaryByLocation;
  locationStats?: LoadedStat[];
  nameProp?: "fullName" | "name";
  locations?: TopicDashboardChartSectionConfig["locations"];
}): LineChartSeries[] => {
  const series: LineChartSeries[] = [];
  const makeSeries = (
    locationStat: LoadedStat | LoadedStat[],
    _location?: { id: string; name: string }
  ) => {
    if (!locationStat) return;
    if (isArray(locationStat)) {
      locationStat.forEach((s) => makeSeries(s));
      return;
    }
    const { timeSeries, statIdentifier, lastValue, lastUpdatedOn } = locationStat as LoadedStat;
    const { values = [], dates = [] } = timeSeries || {};
    series.push({
      name: _location?.name ?? statIdentifier?.[nameProp] ?? "",
      dates: dates ?? [lastUpdatedOn ?? null],
      values: values.length ? values : [lastValue ?? null],
      id: _location?.id ?? statIdentifier?.id
    });
  };

  if (locationStats?.length) {
    locationStats.forEach((s) => makeSeries(s));
    return series;
  }

  if (locationStatDictionary === undefined) return [];
  Object.entries(locationStatDictionary).forEach(([_, statOrDictionary]) => {
    if ("statIdentifier" in statOrDictionary) {
      makeSeries(statOrDictionary);
      return;
    }
    Object.entries(statOrDictionary).forEach(([locationId, stat]) => {
      makeSeries(
        stat as LoadedStat,
        locations?.find(({ id }) => id === locationId)
      );
    });
  });
  return series;
};

const getYAxisOptions = ({
  isPercent,
  series
}: {
  isPercent: boolean;
  series: LineChartSeries[];
}): LineChartProps["yAxisOptions"] => {
  const max = isPercent
    ? 100
    : Math.max(...series.flatMap(({ values }) => Math.max(...(values as number[]))));
  return { max, tickInterval: isPercent ? 5 : determineTickInterval(max) ?? 5 };
};

export interface GetChartableIndicatorsParams {
  locationId: string;
  locationStats: LoadedStatDictionary | LoadedLocationStatDictionaryByLocation;
  series: LineChartSeries[];
  includeStratifications?: boolean;
  stratificationsAsDropdown: boolean;
  xAxisEnhancements?: object;
  granularity?: MhcTimeSeriesGranularityEnum | null;
  yAxisTitleText?: string;
  statIdConfigs?: IdentifierConfig[] | StatIdConfig[] | null;
  overrideProps?: Partial<LineChartProps>;
  forceToMaxValue?: boolean;
}

/**
 * Convert location stats to chartable indicators with data needed for chart
 *
 * @param params
 * @param params.forceToMaxValue
 * @param params.granularity
 * @param params.includeStratifications
 * @param params.locationStats
 * @param params.overrideProps
 * @param params.series
 * @param params.statIdConfigs
 * @param params.stratificationsAsDropdown
 * @param params.xAxisEnhancements
 *
 * @returns indicators and identifier properties for chart
 */
export const getChartableIndicators = ({
  forceToMaxValue = false,
  granularity = null,
  includeStratifications,
  locationStats,
  overrideProps = {},
  series,
  statIdConfigs = null,
  stratificationsAsDropdown,
  xAxisEnhancements
}: GetChartableIndicatorsParams) => {
  let identifierProps = {};
  const indicators: IndicatorInvestigateChartProps["indicators"] = compact(
    series.map((s, i): ChartableStat | null => {
      const stat = (locationStats as LoadedStatDictionary)[s.id as string] as LoadedStat;
      if (isNil(stat) || isNil(stat.statIdentifier)) return null;
      identifierProps = {
        granularity: (stat.granularity || stat.timeSeries?.granularity) ?? null,
        precision: stat.statIdentifier?.precision ?? 0
      };
      const stratifications = includeStratifications ? stat.stratifications ?? null : null;
      let stratificationSeries: LineChartSeries[] = [];
      let stratificationGroups: ChartableStat[] = [];
      if (stratifications?.length && stratificationsAsDropdown) {
        const groups = stat.statIdentifier.stratificationGroups;
        stratificationGroups = groups.map(({ statIdentifiers, ...groupProps }): ChartableStat => {
          const statIds = statIdentifiers.map(({ id }) => id);
          const stratificationsInGroup = stratifications.filter(({ statIdentifier: { id } }) => {
            return statIds.includes(id);
          });
          const {
            subtitle,
            isPercent,
            precision = 0,
            unitLabel,
            statCaption
          } = (stratificationsInGroup[0]?.statIdentifier || {}) as MhcStatIdentifierFragment;
          const series = sortBy(
            getSeriesFromLocationStats({
              locationStats: stratificationsInGroup,
              nameProp: "name"
            }),
            "name"
          );
          return {
            id: groupProps.id ?? i.toString(),
            statCaption: statCaption ?? "",
            title: groupProps.name ?? "Stratification",
            unitLabel: subtitle ?? unitLabel ?? "",
            yAxisTitleText: statCaption ?? unitLabel ?? subtitle ?? "",
            yAxisTitle: statCaption ?? unitLabel ?? subtitle ?? "",
            subtitle: subtitle ?? "",
            isPercent,
            precision,
            xAxisEnhancements,
            granularity: stat?.granularity ?? null,
            yAxisOptions: getYAxisOptions({ isPercent, series }),
            series
          };
        });
        stratificationGroups = stratificationGroups.filter(({ series }) =>
          every(series, (s) => s.values.length > 0)
        );
      } else if (stratifications?.length) {
        stratificationSeries = getSeriesFromLocationStats({
          locationStats: stratifications,
          nameProp: "name"
        });
        if (stat?.stratifications && stat.stratifications.length > 0) {
          const stratifications = stat.stratifications ?? null;
          if (stratifications) {
            stratificationSeries = getSeriesFromLocationStats({
              locationStats: stratifications,
              nameProp: "name"
            });
          }
        }
        if (stratificationSeries.length && series[0]) {
          series[0].name = `${series[0].name as string} (${ENTIRE_GROUP_NAME})`;
        }
      }
      let title = stat?.statIdentifier ? `${(stat?.statIdentifier?.fullName || s.name) ?? ""}` : "";
      if (
        series.filter((s) => {
          const localStat = (locationStats as LoadedStatDictionary)[s.id as string] as LoadedStat;
          return stat.statIdentifier.fullName === localStat.statIdentifier.fullName;
        }).length > 1
      ) {
        title = `${title} (${statTypeName(stat.statIdentifier)})`;
      }
      const {
        isPercent = false,
        precision = 0,
        unitLabel = null,
        statCaption,
        subtitle
      } = stat.statIdentifier;
      const yAxisTitleText = statCaption ?? unitLabel ?? "";
      const _series = stratificationsAsDropdown ? [s] : [s, ...(stratificationSeries || [])];

      const configForSeries = statIdConfigs?.find((config) => {
        if (typeof config === "string") return false;
        return config.identifier === s.id;
      }) as IdentifierConfig | null;

      return Object.assign(
        {
          id: s.id ?? "",
          title,
          series: _series,
          unitLabel: statCaption ?? unitLabel ?? "",
          subtitle: subtitle ?? statCaption ?? "",
          statCaption: statCaption ?? "",
          isPercent,
          precision,
          yAxisTitle: yAxisTitleText,
          // TODO - remove yAxisTitleText in favor of yAxisTitle in LineChart
          yAxisTitleText,
          yAxisOptions:
            forceToMaxValue || isPercent ? getYAxisOptions({ isPercent, series: _series }) : {},
          stratificationGroups,
          xAxisEnhancements,
          granularity: (granularity || stat?.granularity) ?? null,
          group: configForSeries?.group ?? null
        },
        overrideProps
      );
    })
  );

  return { indicators, identifierProps };
};
