import { StaticImport } from "next/dist/shared/lib/get-img-props";
import opioidCrisisStoryImage from "public/images/stories/opioid-crisis/thumb.jpg";
import suicideStoryImage from "public/images/stories/suicide/thumb.jpg";
import vaccinationsStoryImage from "public/images/stories/vaccinations/thumb.jpeg";

export interface DataStory {
  datePublished?: string;
  url: string;
  description: string;
  title: string;
  image: string | StaticImport;
  imageAlt?: string;
  context: string;
  slug: string;
}

export const opioidCrisisStory: DataStory = {
  datePublished: "January 2020",
  url: "/stories/opioid-crisis",
  description:
    "In Delaware, like in many states across the nation, the statistics paint a troubling reality. Over 30,000 adults, 9,000 adolescents, and more than 82 percent of our prison population struggle with mental illness or substance use disorder. ",
  title: "Delaware's Opioid Crisis",
  image: opioidCrisisStoryImage,
  imageAlt: "Pills spilling from medicine bottle",
  context: "Mental Health & Substance Use Disorder",
  slug: "opioid-crisis"
};

export const suicideStory: DataStory = {
  datePublished: "April 2022",
  url: "/stories/suicide",
  description:
    "Nearly 600 Delawareans died by suicide between 2014 and 2018. By examining the data and the characteristics of those who died, DHSS seeks to provide residents, health care professionals, local organizations, and others with information that could help prevent future suicides.",
  title: "Insights from the 2014-2018 Delaware Suicide Surveillance Study",
  image: suicideStoryImage,
  imageAlt: "Man in dark room",
  context: "Mental Health & Substance Use Disorder",
  slug: "suicide"
};

export const vaccinationsStory: DataStory = {
  datePublished: "March 2022",
  url: "/stories/vaccination",
  description:
    "Hispanic Delawareans were hit hard during the pandemic's early months and slow to be vaccinated. Now they top the vaccination tables. What happened?",
  title: "COVID-19 Vaccinations in the Hispanic Community",
  image: vaccinationsStoryImage,
  imageAlt: "Mother with children",
  context: "COVID-19",
  slug: "vaccinations"
};

export const dataStories: DataStory[] = [suicideStory, vaccinationsStory, opioidCrisisStory];
