import isNil from "lodash/isNil";
import { add } from "date-fns/add";
import { isWithinInterval } from "date-fns/isWithinInterval";

import { Duration } from "date-fns/types";
import { MhcTimeSeriesFragment, MhcTimeSeriesGranularityEnum, Scalars } from "graphqlApi/types";

import { getUtcDateFromString, normalizeDate } from "./utcDateFromString";

interface FirstYearData {
  year: string;
  value: number;
}

export const getFirstYearDataFromSeries = (series: MhcTimeSeriesFragment): FirstYearData => {
  const value = series.timeSeries.values[0];
  const baseTimestamp = series.timeSeries.dates ? series.timeSeries.dates[0] : null;
  if (isNil(baseTimestamp) || isNil(value)) {
    return { year: "", value: 0 };
  }
  const date = getUtcDateFromString(baseTimestamp);
  return {
    year: `${date?.getFullYear() ?? ""}`,
    value
  };
};

type FilterAndFillTimeSeriesDatesParams = {
  dates: string[];
  startsOn: Scalars["ISO8601Date"];
  endsOn: Scalars["ISO8601Date"];
  granularity?: MhcTimeSeriesGranularityEnum;
};
/**
 * Receives a list of dates and filters them to fall within the specified range and then
 * adds dates, if necessary, until the end date is reached based on the specified granularity.
 *
 * @param params
 * @param params.dates
 * @param params.startsOn
 * @param params.endsOn
 * @param params.granularity
 *
 * @returns list of dates as Date objects
 *
 * @see MhcTimeSeriesGranularityEnum
 */
export const filterAndFillTimeSeriesDates = ({
  dates,
  startsOn,
  endsOn,
  granularity = MhcTimeSeriesGranularityEnum.Week
}: FilterAndFillTimeSeriesDatesParams): Date[] => {
  const startDate = normalizeDate(new Date(startsOn));
  const endDate = normalizeDate(new Date(endsOn));

  // Filter dates within the specified range
  const filteredDates = dates
    .filter((date) =>
      isWithinInterval(normalizeDate(new Date(date)), { start: startDate, end: endDate })
    )
    .map((d) => normalizeDate(new Date(d)));

  const duration = `${granularity.toLowerCase()}s` as Duration;

  // If there are dates missing, add dates in increments based on granularity
  let currentDate = startDate;
  while (currentDate < endDate) {
    const nextDate = add(currentDate, { [duration as string]: 1 });
    if (
      !filteredDates.find((date) => isWithinInterval(date, { start: currentDate, end: nextDate }))
    ) {
      filteredDates.push(nextDate);
    }
    currentDate = nextDate;
  }

  return filteredDates;
};
