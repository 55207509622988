import without from "lodash/without";

import {
  IdentifierConfig,
  StatIdConfig,
  TopicDashboardSubsectionConfig
} from "../../dashboard/types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

const baseConfig = {
  needSeries: true,
  needStratifications: true
};

const TESTED_PERCENT_SI = "BLL_TESTED_LEAD_TOTAL_PERCENT";
const TESTED_COUNT_SI = "BLL_TESTED_LEAD_TOTAL_COUNT";

export const LEAD_TESTING_IDENTIFIERS: IdentifierConfig[] = [
  {
    ...baseConfig,
    identifier: TESTED_PERCENT_SI,
    relatedIdentifier: TESTED_COUNT_SI,
    granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
  },
  {
    ...baseConfig,
    identifier: TESTED_COUNT_SI,
    granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
  },
  {
    ...baseConfig,
    identifier: "BLL_3_POINT_5_PLUS_LEAD_TOTAL_CRUDE_RATE",
    relatedIdentifier: "BLL_3_POINT_5_PLUS_LEAD_TOTAL_COUNT"
  },
  {
    ...baseConfig,
    identifier: "BLL_5_PLUS_LEAD_TOTAL_CRUDE_RATE",
    relatedIdentifier: "BLL_5_PLUS_LEAD_TOTAL_COUNT"
  }
];

const allGeographiesExceptState = without(Object.values(MhcGeographyEnum), MhcGeographyEnum.State);

export const subSections: TopicDashboardSubsectionConfig[] = [
  {
    id: "testing-children",
    tableConfig: {
      id: "testing-stats-table",
      statIdConfigs: LEAD_TESTING_IDENTIFIERS.filter(
        ({ identifier }) => identifier !== TESTED_COUNT_SI
      ),
      supportedGeographies: [MhcGeographyEnum.State],
      showWithNoData: false,
      props: {
        renderGhostColumns: false,
        columnsToShow: ["primaryStat", "relatedStat", "percent_change", "trendline"]
      }
    },
    kpiGroupConfig: {
      supportedGeographies: allGeographiesExceptState,
      statIdConfigs: [
        {
          ...baseConfig,
          identifier: TESTED_PERCENT_SI,
          granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
        },
        {
          ...baseConfig,
          identifier: TESTED_COUNT_SI,
          granularity: MhcTimeSeriesGranularityEnum.FiveYearWindow
        }
      ]
    },
    chartConfigs: [
      {
        id: "testing-children",
        title: "Testing Children for Lead Poisoning by Age",
        type: "indicator-investigation",
        statIdConfigs: [
          {
            needSeries: true,
            identifier: TESTED_PERCENT_SI,
            granularity: MhcTimeSeriesGranularityEnum.Year
          },
          {
            needSeries: true,
            identifier: TESTED_COUNT_SI,
            granularity: MhcTimeSeriesGranularityEnum.Year
          }
        ],
        timeScale: "yearly",
        granularity: MhcTimeSeriesGranularityEnum.Year,
        supportedGeographies: [MhcGeographyEnum.State],
        showAdditionalInfo: true,
        includeStratifications: true
      },
      {
        id: "testing-children",
        title: "Percent of Children Tested by Age",
        type: "line",
        statIdConfigs: [LEAD_TESTING_IDENTIFIERS[0] as StatIdConfig],
        granularity: LEAD_TESTING_IDENTIFIERS[0]?.granularity,
        supportedGeographies: allGeographiesExceptState,
        showAdditionalInfo: true,
        includeStratifications: true
      }
    ]
  }
];
