import { MhcGeographyEnum, MhcStatIdentifier } from "graphqlApi/types";

/**
 * Utility function utilized to remove the state from the available geographies of a list of stats
 *
 * Since Investigate maps shouldn't display the state as an option in the map type dropdown, the easiest
 * way to avoid this is to remove the geography from the available geographies property of the stats, currently
 * this is the implementation that requires less work on the investigate map. If in the future we decide there
 * might be cases when we do want to display the state geography, this function shouldn't be called.
 *
 * @param stats - Stat identifier list to which the filter will be applied
 *
 * @returns Stat identifier list of stats without the state in their available geographies
 */
export const removeStateFromAvailableGeos = (stats: MhcStatIdentifier[]): MhcStatIdentifier[] => {
  return stats.map((stat) => ({
    ...stat,
    availableGeographies: stat.availableGeographies.filter((geo) => geo !== MhcGeographyEnum.State)
  }));
};
