import compact from "lodash/compact";
import pick from "lodash/pick";
import { max } from "date-fns/max";

import {
  StatIdConfig,
  TopicDashboardChartSectionConfig,
  TopicDashboardSubSectionData
} from "../../elementHelpers/dashboard/types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { createLoadedStatDictionary } from "../util";
import {
  getChartableIndicators,
  GetChartableIndicatorsParams,
  getSeriesFromLocationStats
} from "./util";
import { getMaxDateFromAttributions } from "common/util/attributions";
import { getAttributionsFromStats } from "common/util/formatHelpers/statIdentifierHelpers";

import { IndicatorInvestigateChartProps } from "common/components/charts/Investigate/IndicatorInvestigateChart";
import { LineChartProps } from "common/components/charts/LineChart";
import { getLocationName } from "../../elementHelpers";
import { geographySupportsSection } from "../fetchSectionData";
import {
  getLoadedStatDictionaryForLocation,
  getLoadedStatsForLocation,
  LoadedLocationStatDictionary,
  LoadedLocationStatDictionaryByLocation,
  LoadedStat
} from "../fetchStatsForAllSections";

export interface FetchChartDataProps {
  id: string;
  chartConfigs: TopicDashboardChartSectionConfig[] | null;
  location: {
    locationId: string;
    locationName: string;
    locationGeography: MhcGeographyEnum;
  };
  loadedStats?: LoadedLocationStatDictionaryByLocation;
  noData?: boolean;
}

export const getChartableStats = async ({
  loadedStats,
  locations,
  statIds,
  locationId
}: {
  locationId: string;
  loadedStats?: LoadedLocationStatDictionaryByLocation;
  locations: TopicDashboardChartSectionConfig["locations"];
  statIds: StatIdConfig[];
}) => {
  if (!loadedStats) {
    const locationDict = await createLoadedStatDictionary({
      locationId,
      identifierConfigs: statIds
    });
    return getLoadedStatDictionaryForLocation(locationId, locationDict);
  }

  let locationStats = {};
  locationStats = pick(
    loadedStats,
    statIds.map((config) => (typeof config === "object" ? config.identifier : config))
  );
  if (locations?.length) {
    return locationStats;
  } else {
    return getLoadedStatDictionaryForLocation(locationId, locationStats);
  }
};

export const fetchChartData = async ({
  id,
  chartConfigs,
  location: { locationName, locationId, locationGeography },
  loadedStats,
  noData = false
}: FetchChartDataProps): Promise<TopicDashboardSubSectionData["chartGroup"] | null> => {
  if (!chartConfigs?.length) return null;
  const charts = compact(
    await Promise.all(
      chartConfigs.map(
        async ({
          forceToMaxValue = false,
          forLocation,
          granularity = null,
          id = "",
          includeStratifications = false,
          locations,
          overrideProps = {},
          plotLines,
          statIdConfigs,
          stratificationsAsDropdown = false,
          supportedGeographies,
          title = "",
          type,
          yAxisTitleText = ""
        }) => {
          if (!statIdConfigs || !geographySupportsSection(supportedGeographies, locationGeography))
            return null;
          const locationStats = (await getChartableStats({
            locationId: forLocation ?? locationId,
            loadedStats,
            locations,
            statIds: statIdConfigs
          })) as LoadedLocationStatDictionaryByLocation | LoadedLocationStatDictionary;
          const series = getSeriesFromLocationStats({
            locationStatDictionary: locationStats,
            locations
          });

          const xAxisEnhancements = plotLines ? { plotLines } : {};
          let props: LineChartProps | IndicatorInvestigateChartProps | null = null;
          const baseOptions: GetChartableIndicatorsParams = {
            locationId,
            series,
            includeStratifications,
            stratificationsAsDropdown: false,
            locationStats,
            xAxisEnhancements,
            granularity,
            yAxisTitleText,
            overrideProps,
            forceToMaxValue
          };

          // Line chart of single identifier (maybe with stratifications)
          if (type === "line") {
            const { indicators } = getChartableIndicators(baseOptions);
            props = indicators[0] as LineChartProps;
          }

          // Line chart for multiple indicators or a group of indicators
          if (type === "indicator-investigation") {
            const { indicators, identifierProps } = getChartableIndicators({
              ...baseOptions,
              stratificationsAsDropdown,
              statIdConfigs
            });

            props = {
              indicators,
              locationName: getLocationName({ name: locationName, geography: locationGeography }),
              ...identifierProps,
              title
            } as IndicatorInvestigateChartProps;
          }

          const locationComparison = type === "location-comparison" && locations?.length;

          // Line chart of single identifier (maybe with stratifications)
          if (locationComparison) {
            const { indicators } = getChartableIndicators({
              ...baseOptions,
              locationStats: Object.values(locationStats)[0] as LoadedLocationStatDictionary
            });
            const indicator = indicators[0] as LineChartProps;
            props = {
              ...indicator,
              series: indicators.flatMap(({ series }) => series)
            };
          }

          const attributions = getAttributionsFromStats(
            locationComparison
              ? getLoadedStatsForLocation(
                  locationId,
                  locationStats as LoadedLocationStatDictionaryByLocation
                )
              : (Object.values(locationStats as LoadedLocationStatDictionary) as LoadedStat[])
          );

          const latestSeriesDate = max(
            series.map(({ dates = [] }) => max(dates.map((d) => new Date(d))))
          );

          if (!props) return null;

          return {
            id,
            noData,
            title,
            attributions,
            props,
            type: type ?? "line",
            latestDate: latestSeriesDate?.toDateString() ?? null,
            updatedDate: getMaxDateFromAttributions(attributions)?.getTime() ?? null
          };
        }
      )
    )
  );
  return {
    id,
    charts,
    latestDate: "",
    updatedDate:
      getMaxDateFromAttributions(
        charts.flatMap(({ attributions }) => attributions)
      )?.toDateString() ?? null,
    aboutTheDataContent: chartConfigs.flatMap(
      ({ aboutTheDataContent }) => aboutTheDataContent ?? []
    )
  };
};
