import { BaseProps, TopicElementBaseRender } from "modules/Topics/types";

import { MhcAlert } from "common/components/Alerts/MhcAlert";

export const getEmergencyAlertElement = <P extends BaseProps>(
  id: string
): TopicElementBaseRender<P> => {
  return {
    id,
    render: ({ locationNotAvailable }) =>
      locationNotAvailable ? null : (
        <MhcAlert severity="error" sx={{ bgcolor: "white", mb: 0 }}>
          For Police or Medical Emergency Call 911
        </MhcAlert>
      )
  };
};
