import { MhcGeographyEnum, MhcLocationFragment } from "graphqlApi/types";

import { createFeatureCollectionFromGeoJsons } from "common/components/GeoMap/util/createFeatureCollectionFromGeoJsons";

import { InvestigateMapAvailableGeographies } from "common/components/InvestigateMap/InvestigateMap";

export type MapGeographies = Record<string, MhcLocationFragment[]>;

export const getMapGeographyJson = (
  geographies: MapGeographies
): InvestigateMapAvailableGeographies[] =>
  Object.entries(geographies).map(([geography, geographyLocations]) => {
    return {
      geography: geography as MhcGeographyEnum,
      geoJSON: {
        type: "FeatureCollection",
        features: createFeatureCollectionFromGeoJsons(geographyLocations)
      }
    };
  });
