const cprBase = "/portals/cpr";

export const cprLocationPagePath = ({
  locationId = "",
  anchorId
}: {
  locationId?: string;
  anchorId?: string;
}): string => {
  const anchor = anchorId ? `#${anchorId}` : "";
  return `${cprBase}/${locationId}${anchor}`;
};

export const cprHomePagePath = (locationId?: string) => {
  if (!locationId || locationId === "state") return cprBase;
  return cprLocationPagePath({ locationId });
};
