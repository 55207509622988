import {
  TopicDashboardSectionProps,
  TopicDashboardSubSectionData
} from "./elementHelpers/dashboard/types";

import { getLatestDateFromArray } from "common/util/getLatestDate";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { ReportDatesProps } from "common/components/ReportDates/ReportDates";

interface ReturnProps {
  id: string;
  title: string;
  dates: ReportDatesProps;
  subSections: TopicDashboardSubSectionData[];
}

export const getTopicDashboardSection = (
  sections: TopicDashboardSectionProps[],
  id: string
): ReturnProps => {
  const latestDates = sections.flatMap((section) => section.latestDate ?? null);
  const latestDate = getLatestDateFromArray(latestDates);
  const {
    title = "",
    subSections = [],
    updatedDate = 0,
    granularity
  } = sections?.find(({ id: sectionId }) => sectionId === id) ?? {};
  let _updatedDate = undefined;
  if (updatedDate) {
    _updatedDate = getUtcDateFromString(updatedDate) as Date;
  }
  const dates: ReportDatesProps = {
    dataDate: latestDate ? getUtcDateFromString(latestDate) ?? undefined : undefined,
    updatedDate: _updatedDate,
    granularity
  };

  return {
    id,
    title,
    dates,
    subSections
  };
};
